import styled from "styled-components/macro";
import { H6 } from "../../textElements";

interface ExpandableTextProps {
  height?: number;
  isBold?: boolean;
}

export const ExpandableTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  height: fit-content;
`;

export const TextHeader = styled.div`
  display: flex;
  flex-direction: row;
  background-color: transparent;
  width: 100%;
  cursor: pointer;
  gap: 8px;
  align-items: center;
`;

export const TextContent = styled(H6)<ExpandableTextProps>`
  margin-bottom: 0;
  font-weight: ${(props) => (props.isBold ? "500" : "400")};
  color: var(--grayscale6);
`;

export const TextBodyContainer = styled.div<ExpandableTextProps>`
  height: 100%;

  div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const TextBodyContent = styled.div`
  color: var(--grayscale6);
  margin: 8px;
  gap: 4px;

  &:first-of-type {
    margin-left: 0;
  }
`;

export const TextBodyContentNumber = styled.div`
  color: var(--grayscale5);
`;
