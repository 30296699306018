import { FC } from "react";
import { ScratchQueryQuery } from "../../../../gql/graphql";
import { PillLabelContainer } from "../../../atoms/pillLabel/pillLabelStyle";
import {
  TextBodyContent,
  TextBodyContentNumber,
} from "../../expandableText/expandableTextStyle";

// components
import PillLabel from "../../../atoms/pillLabel/PillLabel";
import ExpandableText from "../../expandableText/ExpandableText";
import ItemDetailButton from "../../itemDetailButton/ItemDetailButton";
import styled from "styled-components";
import { H5, N1 } from "../../../textElements";

export const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ProductCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: auto;
  max-width: var(--containerSize);
  margin-bottom: 24px;
`;

export const ProductCardBrandText = styled(H5)`
  color: var(--grayscale5);
  margin-bottom: 12px;
`;

export const ProductCardSkuText = styled(N1)`
  color: var(--black);
  text-transform: uppercase;
`;

type ReceiptGroupCardType = {
  itemDetails: ScratchQueryQuery["items"][0];
  scannedReceiptId: string;
};

const ReceiptGroupCard: FC<ReceiptGroupCardType> = ({
  itemDetails,
  scannedReceiptId,
}) => {
  if (!itemDetails) {
    return null;
  }

  const product = itemDetails.product;

  const TextElement = (
    <div>
      <ProductCardBrandText>{itemDetails.partner?.name}</ProductCardBrandText>
      {product?.sku ? (
        <ProductCardSkuText>Sku: {product?.sku}</ProductCardSkuText>
      ) : (
        <ProductCardSkuText>ID: {scannedReceiptId}</ProductCardSkuText>
      )}
      <PillLabelContainer>
        <PillLabel theme="inProgressStatus" labelText={"In Progress"} />
        <PillLabel
          theme="location"
          labelText={itemDetails.sublocation?.displayName || "Unknown"}
        />
        {itemDetails.productType?.code && (
          <PillLabel
            theme="furnitureType"
            labelText={itemDetails.productType?.code}
          />
        )}
      </PillLabelContainer>
    </div>
  );

  return (
    <>
      <ProductCardContainer>
        <TopRow>
          {TextElement}
          <ItemDetailButton heroImage={itemDetails?.product?.heroImage} />
        </TopRow>

        <ExpandableText
          headerText={`(${itemDetails?.receipts?.length}) Linked Receipts`}
          isBold={true}
        >
          <>
            {itemDetails?.receipts?.map((linkedReceipt, index) => (
              <TextBodyContent key={index}>
                <TextBodyContentNumber>{index + 1}</TextBodyContentNumber>
                {linkedReceipt.barcode}
              </TextBodyContent>
            ))}
          </>
        </ExpandableText>
      </ProductCardContainer>
    </>
  );
};

export default ReceiptGroupCard;
