const authCodeKey = "authCode";

const getAuthCode = (): string | null => {
  return localStorage.getItem(authCodeKey);
};

const setAuthCode = (code: string) => {
  localStorage.setItem(authCodeKey, code);
};

const useAuth = (): [() => string | null, (code: string) => void] => {
  return [getAuthCode, setAuthCode];
};

export default useAuth;
