export const DummyData = {
  safetyCheckboxes: [
    {
      value: false,
      text: "Instability or Wobble",
      id: 1,
    },
    {
      value: false,
      text: "Faulty Electrical Components",
      id: 2,
    },
    {
      value: false,
      text: "Chipped or Cracked Glass",
      id: 3,
    },
  ],
  appearanceCheckboxValues: [
    {
      value: false,
      text: "Rust",
      id: 1,
    },
    {
      value: false,
      text: "Missing or Mismatched Cushions",
      id: 2,
    },
    {
      value: false,
      text: "Unsanitary Staining or Odor",
      id: 3,
    },
  ],
  nonfunctionalCheckboxValues: [
    {
      value: false,
      text: "Missing or Broken Slats",
      id: 1,
    },
    {
      value: false,
      text: "Broken Frame or Supports",
      id: 2,
    },
    {
      value: false,
      text: "Misaligned Components",
      id: 3,
    },
  ],
};
