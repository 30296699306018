import GlobalStyle from "../src/globalStyles";
import { Routes, Route } from "react-router-dom";
import useCheckAuth from "./hooks/useCheckAuth";
import { SIDEBAR_DATA } from "./components/elements/sidebar/SidebarData";

//Components
import Sidebar from "../src/components/elements/sidebar/Sidebar";
import SidebarItems from "./components/elements/sidebar/SidebarList";
import Home from "./components/pages/home/Home";
import Login from "./components/pages/login/Login";

function App() {
  useCheckAuth();

  return (
    <div className="App">
      <GlobalStyle />
      <Sidebar>
        <Routes>
          <Route path="/" element={<SidebarItems />}>
            {SIDEBAR_DATA.map((item, index) => (
              <Route
                key={index}
                path={item.path}
                element={<item.component />}
              />
            ))}
            <Route path={"login"} element={<Login />} />
            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
      </Sidebar>
    </div>
  );
}

export default App;
