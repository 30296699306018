import { useImmer } from "use-immer";
import { ReceivingManifestFragmentFragment } from "../../../gql/graphql";
import { useNavigate } from "react-router-dom";

import { H0 } from "../../textElements";
import { rootDimensions } from "../../../globalStyles";

import ProductReceivingResult from "./productReceivingResult/ProductReceivingResult";
import SlideInButton from "../../elements/slideInButton/SlideInButton";
import Notification from "../../elements/notification/Notification";

const ProductReceiving = ({
  receivingManifests,
}: {
  receivingManifests: readonly ReceivingManifestFragmentFragment[];
}) => {
  const [scannedLocationId, setScannedLocationId] = useImmer<string>("");
  const [locationIdError, setLocationIdError] = useImmer<string>("");
  const [openCompleteNotification, setOpenCompleteNotification] =
    useImmer<boolean>(false);
  const navigate = useNavigate();

  const onSubmitTrackingNumber = (newValue: string, event: any) => {
    event.preventDefault();
    navigate(`/product-receiving/${newValue}`);
  };

  const onCompleteReceiving = () => {
    setOpenCompleteNotification(false);
    navigate(`/product-receiving`);
  };

  const TrackingBarcodeResult = (
    <>
      <ProductReceivingResult
        receivingManifests={receivingManifests}
        onSubmitTrackingNumber={onSubmitTrackingNumber}
        scannedLocationId={scannedLocationId}
        setScannedLocationId={setScannedLocationId}
        locationIdError={locationIdError}
      />
      {scannedLocationId && (
        <SlideInButton
          leftOffset={rootDimensions.closedSidebarWidth}
          onClick={() => setOpenCompleteNotification(true)}
          label="Complete receiving"
          visible={true}
        />
      )}
      {openCompleteNotification && (
        <Notification
          text="Receiving complete"
          open={openCompleteNotification}
          onClose={() => onCompleteReceiving()}
        />
      )}
    </>
  );

  return <>{TrackingBarcodeResult}</>;
};

export default ProductReceiving;
