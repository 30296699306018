import { FC } from "react";
import { H5 } from "../../textElements";
import {
  RadioButtonQuestionText,
  RadioButtonLink,
  RadioButtonQuestionContent,
} from "../../atoms/radioButtons/radioButtonsStyle";
import RadioButtons from "../../atoms/radioButtons/RadioButtons";

type RadioButtonQuestionTypes = {
  questionText: string;
  radioValue: string;
  linkText?: string;
  onButtonClick?: () => void;
  onSelect: (newValue: string) => void;
};

const RadioButtonQuestion: FC<RadioButtonQuestionTypes> = ({
  questionText,
  radioValue,
  linkText,
  onButtonClick,
  onSelect,
}) => {
  return (
    <RadioButtonQuestionContent>
      <RadioButtonQuestionText>
        <H5>{questionText}</H5>
        {onButtonClick && (
          <RadioButtonLink onClick={onButtonClick}>{linkText}</RadioButtonLink>
        )}
      </RadioButtonQuestionText>
      <RadioButtons onSelect={onSelect} radioValue={radioValue} />
    </RadioButtonQuestionContent>
  );
};

export default RadioButtonQuestion;
