import styled from "styled-components/macro";
import { H5, P1 } from "../../../textElements";

interface DamageCardProps {
  isSelected?: boolean;
  isEmpty?: boolean;
}

export const DamageCardGroup = styled.div<DamageCardProps>`
  display: ${(props) => (props.isEmpty ? "none" : "flex")};
  flex-direction: column;
  margin: 8px 0;
  gap: 24px;

  .damage-card:last-of-type {
    .completed-selection-row {
      margin-bottom: 0;
    }
  }
`;

export const DamageCardHeader = styled(H5)`
  margin-bottom: 0;
`;

export const EditDamageCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid var(--grayscale3);
  border-radius: 6px;

  .selection-row:last-of-type {
    border-bottom: none;
    border-radius: 0 0 6px 6px;
  }

  .selection-row:first-of-type {
    border-radius: 6px 6px 0 0;
  }
`;

export const DamageHeader = styled(P1)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--grayscale2);
  padding: 8px 16px;
  cursor: pointer;
`;

export const CompletedTitle = styled(P1)`
  color: var(--black)
  place-self: flex-start;
  margin-top: 8px;
  min-width: 60px;
`;

export const SelectedButton = styled.button<DamageCardProps>`
  cursor: pointer;
  background-color: ${(props) =>
    props.isSelected ? "var(--grayscale2)" : "var(--white)"};
  border: 1px solid var(--grayscale2);
  border-radius: 15px;
  padding: 6px 16px;
  margin: 0 4px 0px 0;
  place-self: center;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;

  &:first-child {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  :disabled {
    background-color: var(--grayscale3);
    color: var(--grayscale5);
    cursor: not-allowed;
  }
`;

export const CompletedDamageCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--grayscale0);
  border: 1px solid var(--grayscale3);
  border-radius: 6px;
  padding: 8px 16px;
  gap: 16px;

  .completed-selection-row:last-of-type {
    margin-bottom: 0px;
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const EllipsisButton = styled.button`
  place-self: flex-start;
  margin-top: 8px;
  background-color: transparent;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const LeftSide = styled.div`
  display: flex;
  gap: 16px;
`;
