import React, { useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { apiUrl, currentServer } from "../../../environment";
import { useNavigate, useSearchParams } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";

const HELLO_QUERY = gql`
  query hello {
    hello
  }
`;

function Greeting() {
  let { data, loading, error } = useQuery(HELLO_QUERY);
  if (loading) {
    return <span>Waiting on graphql server...</span>;
  }
  if (error) {
    return <span>Error: {error.message}</span>;
  }

  return data.hello;
}

const Login: React.FC = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const [, setAuthCode] = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (code) {
      setAuthCode(code);
      navigate("/");
    }
  }, [code, navigate, setAuthCode]);

  return (
    <>
      <p>
        <Greeting />, click below to login if currently unauthenticated.
      </p>
      <a
        className="App-link"
        href={`${apiUrl}/auth/login?org=org_01GSVACZ278JQ08X4JYBFWAYES&dest=${currentServer}/login`}
        rel="noopener noreferrer"
      >
        Login
      </a>
    </>
  );
};

export default Login;
