import { FC } from "react";
import { useImmer } from "use-immer";
import { useQuery } from "@apollo/client";
import { PRODUCT_DAMAGE_QUERY } from "../../../queries/conditionAssessment/ProductDamageOptions";

import { H0 } from "../../textElements";

import BarcodeInput from "../../atoms/barcodeInput/BarcodeInput";
import ConditionAssessmentResult from "./ConditionAssessmentResult";
import { useNavigate, useParams } from "react-router-dom";

const ConditionAssessment: FC = () => {
  // TODO: graphql call for here for the side effect of populating the apollo cache for when we _do_ need it shortly
  useQuery(PRODUCT_DAMAGE_QUERY);
  const [receiptError, setReceiptError] = useImmer<string>("");
  const { receiptId } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <H0>Condition Assessment</H0>
      {!receiptId ? (
        <BarcodeInput
          barcodeTitle="Search receipt ID"
          errorMessage={receiptError}
          onSubmitId={(newVal, e) => {
            e.preventDefault();
            navigate(`/condition-assessment/${newVal}`);
          }}
        />
      ) : (
        <ConditionAssessmentResult
          scannedReceiptId={receiptId}
          setReceiptError={setReceiptError}
        />
      )}
    </>
  );
};

export default ConditionAssessment;
