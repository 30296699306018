import styled from "styled-components/macro";
import { H1, H6, P3 } from "../../textElements";

export const ModalWrapper = styled.div`
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(107, 114, 128, 0.5);
  width: 100%;
  z-index: 10;
`;

export const ModalElement = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--white);
  width: fit-content;
  max-width: 496px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  border-radius: 8px;
  padding: 40px;
  margin-top: 0px;

  ::-webkit-scrollbar {
    width: 12px !important;
    scroll-behavior: smooth !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--grayscale4) !important;
    border-radius: 8px !important;
  }
`;

export const ModalText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
`;

export const ModalHeader = styled(H1)`
  margin-bottom: 0;
`;

export const ModalSubheader = styled(P3)``;

export const ModalButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

export const ModalTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const ModalTextHeader = styled(H6)`
  margin-bottom: 8px;
`;

export const ModalTextContent = styled(P3)``;

export const ModalImageContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  gap: 12px;
  margin-top: 8px;
`;

export const ModalIcon = styled.img`
  margin-bottom: 24px;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
`;
