import { FC } from "react";
import { ReceivingManifest } from "../../../../gql/graphql";
import {
  ProductCardBrandText,
  ProductCardSkuText,
  ProductCardText,
  ProductCardCarrierText,
  ProductCardTrackingNumber,
} from "../../home/searchResultProduct/searchResultProductStyle";

type ReceivingManifestDetailsProps = {
  manifest: ReceivingManifest;
};

const ReceivingManifestDetails: FC<ReceivingManifestDetailsProps> = ({
  manifest,
}) => {
  const carrierName = manifest?.shippingData?.carrier?.name;
  const trackingNumber = manifest?.shippingData?.trackingNumber;
  const partnerName = manifest?.partner?.name;

  return (
    <>
      <ProductCardBrandText>{partnerName}</ProductCardBrandText>
      <ProductCardText>
        <ProductCardSkuText>
          {manifest?.returnMerchandiseAuthorization?.rmaNumber ||
            manifest?.advancedShipmentNotification?.partnerAsnId}
        </ProductCardSkuText>
        <ProductCardCarrierText>
          {carrierName}
          {!!trackingNumber && (
            <>
              {" "}
              / Tracking #:
              <ProductCardTrackingNumber>
                {trackingNumber}
              </ProductCardTrackingNumber>
            </>
          )}
        </ProductCardCarrierText>
      </ProductCardText>
    </>
  );
};

export default ReceivingManifestDetails;
