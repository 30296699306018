import React, { FC } from "react";
import Portal from "../portal/Portal";
import { ModalWrapper, ModalElement } from "./modalStyle";

type ModalProps = {
  children: React.ReactNode;
  open: boolean;
  onClose: React.MouseEventHandler<HTMLElement>;
};

const Modal: FC<ModalProps> = ({ children, open, onClose }) => {
  if (!open) {
    return null;
  }

  return (
    <Portal>
      <ModalWrapper onClick={onClose} className="modal-wrapper">
        <ModalElement className="modal-element">{children}</ModalElement>
      </ModalWrapper>
    </Portal>
  );
};

export default Modal;
