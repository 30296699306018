import { graphql } from "../../gql/gql";

export const PRODUCT_DETAILS_QUERY = graphql(/* GraphQL */ `
  query ScratchQuery($receiptIds: [String!]!) {
    items(barcodes: $receiptIds) {
      receiptGroupId
      partner {
        name
      }
      product {
        sku
        heroImage
      }
      productType {
        code
        displayName
      }
      receipts {
        barcode
      }
      sublocation {
        displayName
      }
    }
  }
`);
