import { FC, useId } from "react";
import {
  CheckboxLabel,
  CheckboxContainer,
  CheckboxInput,
} from "./checkboxStyle";

type CheckboxCollectionProps = {
  value: boolean;
  labelText: string;
  setCheckboxValue: (newValue: boolean) => void;
};

const CheckboxCollection: FC<CheckboxCollectionProps> = ({
  value,
  labelText,
  setCheckboxValue,
}) => {
  const onOptionChange = (e: any) => {
    setCheckboxValue(e.target.checked);
  };

  const CheckboxId = useId();
  const CheckboxName = useId();

  return (
    <CheckboxContainer>
      <CheckboxLabel>
        <CheckboxInput
          type="checkbox"
          id={CheckboxId}
          name={CheckboxName}
          checked={value}
          onChange={onOptionChange}
        />
        {labelText}
      </CheckboxLabel>
    </CheckboxContainer>
  );
};

export default CheckboxCollection;
