import React, { FC, useContext } from "react";
import ExpandableCard from "../../../elements/expandableCard/ExpandableCard";
import { ExpandableCardSection } from "../../../elements/expandableCard/expandableCardStyle";
import CheckboxCollection from "../../../atoms/checkbox/CheckboxCollection";
import { FunctionalCheckCheckboxesContext } from "./functionalCheckCheckboxesContext";

const FunctionalCheckCheckboxes: FC = () => {
  const { functionalCheckboxes } = useContext(FunctionalCheckCheckboxesContext);

  return (
    <>
      <ExpandableCardSection>
        <>
          {functionalCheckboxes.map(({ title, values, updateValue }) => {
            return (
              <ExpandableCard headerText={title} key={title}>
                <CheckboxCollection
                  checkboxValues={values}
                  setCheckboxValues={updateValue}
                />
              </ExpandableCard>
            );
          })}
        </>
      </ExpandableCardSection>
    </>
  );
};

export default FunctionalCheckCheckboxes;
