import React, { FC } from "react";
import Checkbox from "./Checkbox";
import { CheckboxGroup } from "./checkboxStyle";

type CheckboxCollectionProps = {
  checkboxValues: any;
  setCheckboxValues: (newValue: boolean, id: number) => void;
};

const CheckboxCollection: FC<CheckboxCollectionProps> = ({
  checkboxValues,
  setCheckboxValues,
}) => {
  return (
    <CheckboxGroup>
      {checkboxValues.map((checkbox: any, index: number) => (
        <Checkbox
          value={checkbox.value}
          labelText={checkbox.text}
          key={index}
          setCheckboxValue={(newValue: boolean) => {
            setCheckboxValues(newValue, index);
          }}
        />
      ))}
    </CheckboxGroup>
  );
};

export default CheckboxCollection;
