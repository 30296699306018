import { FC, useState } from "react";
import CompletedDamageCard from "./CompletedDamageCard";
import EditDamageCard from "./EditDamageCard";
import { DamageInstance } from "./damageCardContext";

type DamageCardProps = {
  damage: DamageInstance;
  isEditable: boolean;
};

const DamageCard: FC<DamageCardProps> = ({ damage, isEditable }) => {
  const [openEdit, setOpenEdit] = useState(false);
  const shouldBeOpen = openEdit || !damage.complete;

  let damageCardElement;
  if (shouldBeOpen) {
    damageCardElement = (
      <EditDamageCard damage={damage} changeToEdit={setOpenEdit} />
    );
  } else {
    damageCardElement = (
      <CompletedDamageCard
        selectedOptions={damage}
        changeToEdit={setOpenEdit}
        isEditable={isEditable}
      />
    );
  }

  return <div className="damage-card">{damageCardElement}</div>;
};

export default DamageCard;
