import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { apiUrl } from "../environment";
import { setContext } from "@apollo/client/link/context";
import useAuth from "../hooks/useAuth";

const link = createHttpLink({
  uri: `${apiUrl}/graphql`,
  credentials: "include",
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const [getAuthToken] = useAuth();
  const token = getAuthToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      ReceivingManifest: {
        fields: {
          receipts: {
            merge(existing = [], incoming: any[]) {
              return [...incoming];
            },
          },
        },
      },
    },
  }),
  link: authLink.concat(link),
});
