import React, { FC, useContext, useState } from "react";
import {
  CompletedDamageCardContainer,
  SelectedButton,
  EllipsisButton,
  ButtonContainer,
  LeftSide,
} from "./damageCardStyle";
import EllipsisIcon from "../../../../assets/icons/EllipsisIcon.svg";
import { DamageCardContext, DamageOptionKey } from "./damageCardContext";
import ConfirmationModal from "../../../elements/modal/ConfirmationModal";
import { DamageInstance } from "./damageCardContext";
import Tooltip from "../../../atoms/tooltip/Tooltip";

type CompletedDamageCardType = {
  selectedOptions: DamageInstance;
  changeToEdit: (newVal: boolean) => void;
  isEditable: boolean;
};

const CompletedDamageCard: FC<CompletedDamageCardType> = ({
  selectedOptions,
  changeToEdit,
  isEditable = true,
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openResolveModal, setOpenResolveModal] = useState<boolean>(false);

  // TODO: connect this with real data later as this is just a visual
  const [isCardResolved, setIsCardResolved] = useState<boolean>(false);

  const { id } = selectedOptions;

  const orderedKeys: DamageOptionKey[] = [
    "componentType",
    "visibilityType",
    "materialType",
    "damageType",
    "severityType",
  ];

  const { deleteDamageCard, duplicateDamageCard, resolveDamageCard } =
    useContext(DamageCardContext);

  let tooltipContent = [];
  if (isEditable) {
    tooltipContent = [
      { linkBehavior: () => changeToEdit(true), linkName: "Edit" },
      {
        linkBehavior: () => duplicateDamageCard(selectedOptions),
        linkName: "Duplicate",
      },
      { linkBehavior: () => setOpenDeleteModal(true), linkName: "Delete" },
    ];
  } else {
    tooltipContent = [
      {
        linkBehavior: () => {
          setOpenResolveModal(true);
          //resolveDamageCard(id),
        },
        linkName: "Resolve",
      },
    ];
  }

  return (
    <>
      <CompletedDamageCardContainer className="completed-selection-row">
        <LeftSide>
          <ButtonContainer>
            {orderedKeys.map((key) => {
              return (
                <SelectedButton
                  isSelected={true}
                  disabled={isCardResolved}
                  type="button"
                  onClick={() => changeToEdit(true)}
                >
                  {selectedOptions[key]}
                </SelectedButton>
              );
            })}
          </ButtonContainer>
        </LeftSide>
        {!isCardResolved && (
          <Tooltip content={tooltipContent}>
            <EllipsisButton>
              <img src={EllipsisIcon} alt="ellipsis" />
            </EllipsisButton>
          </Tooltip>
        )}
      </CompletedDamageCardContainer>
      {openDeleteModal && deleteDamageCard && (
        <ConfirmationModal
          header="Delete damage? Deleting this damage will result in the logged information being lost."
          open={openDeleteModal}
          onConfirm={() => deleteDamageCard(id)}
          onClose={() => setOpenDeleteModal(false)}
        />
      )}
      {openResolveModal && (
        <ConfirmationModal
          header="Are you sure you want to resolve this damage? This cannot be undone."
          open={openResolveModal}
          onConfirm={() => setIsCardResolved(true)}
          onClose={() => setOpenResolveModal(false)}
        />
      )}
    </>
  );
};

export default CompletedDamageCard;
