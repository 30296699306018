import { gql, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const useCheckAuth = () => {
  const CHECK_AUTH_QUERY = gql`
    query hello {
      hello
    }
  `;

  const navigate = useNavigate();
  const { data } = useQuery(CHECK_AUTH_QUERY);
  useEffect(() => {
    if (data?.hello === "Hello unauthenticated") {
      // navigate to component that allows user to login instead of potentially causing an
      // infinite redirect loop
      navigate("/login");
    }
  }, [data, navigate]);
};

export default useCheckAuth;
