import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  RECEIVING_MANIFEST_QUERY,
  ReceivingManifestFragment,
} from "../../../queries/productReceiving/ReceivingManifest";
import { useImmer } from "use-immer";
import { getFragmentData } from "../../../gql";

import { H0 } from "../../textElements";
import { LoadingElement } from "./productReceivingStyle";

import UnknownNumberForm from "./unknownNumberForm/UnknownNumberForm";
import ProductReceiving from "./ProductReceiving";
import BarcodeInput from "../../atoms/barcodeInput/BarcodeInput";

const ProductReceivingInit: FC = () => {
  const { trackingId } = useParams();
  // TODO: Don't do hacky self routing
  const showUnknownNumberForm = trackingId === "unknownNumber";
  const navigate = useNavigate();

  const {
    data: manifestData,
    loading: isLoadingManifest,
    error: errorManifest,
  } = useQuery(RECEIVING_MANIFEST_QUERY, {
    // || "" here is to shush typescripts warnings. since we have the `skip`, it will always be an actual value
    variables: { receivingManifestIds: [trackingId || ""] },
    skip: !trackingId || showUnknownNumberForm,
  });

  const unknownNumberTooltipContent = [
    {
      linkName: "Unknown RMA, ASN or tracking number",
      linkBehavior: () => {
        navigate("/product-receiving/unknownNumber");
      },
    },
  ];

  const onUnknownNumberFormSubmit = () => {
    // TODO: Query to submit unknown data and set returned trackingId
    // TODO: Need BE to submit unknown receiving manifest as result
    const fakeId = "UNK-9W6OFT";
    navigate(`/product-receiving/${fakeId}`);
  };

  const receivingManifests =
    getFragmentData(
      ReceivingManifestFragment,
      manifestData?.receivingManifests
    ) || [];

  const barcodeInput = (
    <BarcodeInput
      barcodeTitle="Search ASN, RMA or tracking number"
      errorMessage={errorManifest?.message || ""}
      tooltipContent={unknownNumberTooltipContent}
      onSubmitId={(newValue) => {
        navigate(`/product-receiving/${newValue}`);
      }}
    />
  );

  return (
    <>
      <H0>Product Receiving</H0>
      {showUnknownNumberForm ? (
        <UnknownNumberForm onSubmit={onUnknownNumberFormSubmit} />
      ) : isLoadingManifest ? (
        <LoadingElement>Loading...</LoadingElement>
      ) : receivingManifests[0] ? (
        <ProductReceiving receivingManifests={receivingManifests} />
      ) : (
        barcodeInput
      )}
    </>
  );
};

export default ProductReceivingInit;
