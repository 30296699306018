import styled from "styled-components/macro";
import { N1, N2, H3, H5, P1 } from "../../../textElements";

interface ProductCardProps {}

export const ProductCardContainer = styled.div<ProductCardProps>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: auto;
  max-width: var(--containerSize);
  margin-bottom: 24px;
`;

export const SearchResultProductContainer = styled(ProductCardContainer)`
  margin-top: 72px;
`;

export const ProductCardText = styled.div``;

export const ProductCardBrandText = styled(H5)`
  color: var(--grayscale5);
  margin-bottom: 12px;
`;

export const ProductCardSkuText = styled(N1)`
  color: var(--black);
  text-transform: uppercase;
`;

export const ProductCardContentText = styled(P1)`
  color: var(--grayscale5);
  padding: 4px 0;

  &:last-of-type {
    padding-bottom: 0;
    margin-bottom: 24px;
  }
`;

export const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ProductCardCarrierText = styled(H3)`
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  padding-bottom: 0;
`;

export const ProductCardTrackingNumber = styled(N2)`
  text-transform: uppercase;
  margin-left: 4px;
`;
