/* eslint-disable */
import * as types from "./graphql";
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  "\n  query hello {\n    hello\n  }\n": types.HelloDocument,
  "\n    query hello {\n      hello\n    }\n  ": types.HelloDocument,
  "\n  mutation AddReceiptFromReceivingManifest(\n    $barcodes: [String!]!\n    $receivingManifestId: UUID!\n    $sublocationReference: SublocationReferenceInput!\n  ) {\n    activateReceipts(\n      barcodes: $barcodes\n      receivingManifestId: $receivingManifestId\n      sublocationReference: $sublocationReference\n    ) {\n      ...ReceivingManifestFragment\n    }\n  }\n":
    types.AddReceiptFromReceivingManifestDocument,
  "\n  mutation RemoveReceiptFromReceivingManifest(\n    $barcodes: [String!]!\n    $receivingManifestId: UUID!\n  ) {\n    removeReceiptGroupFromReceivingManifest(\n      barcodes: $barcodes\n      receivingManifestId: $receivingManifestId\n    ) {\n      ...ReceivingManifestFragment\n    }\n  }\n":
    types.RemoveReceiptFromReceivingManifestDocument,
  "\n  query ProductDamageMetaData {\n    materials {\n      code\n      displayName\n      description\n      damageTypesAndTheirSeverities {\n        damageType {\n          code\n          displayName\n          description\n        }\n        severities {\n          level\n          description\n        }\n      }\n    }\n    productTypes {\n      code\n      description\n      componentTypes {\n        identifier\n        visibilities {\n          visibility {\n            code\n          }\n        }\n      }\n    }\n  }\n":
    types.ProductDamageMetaDataDocument,
  "\n  query ScratchQuery($receiptIds: [String!]!) {\n    items(barcodes: $receiptIds) {\n      receiptGroupId\n      partner {\n        name\n      }\n      product {\n        sku\n        heroImage\n      }\n      productType {\n        code\n        displayName\n      }\n      receipts {\n        barcode\n      }\n      sublocation {\n        displayName\n      }\n    }\n  }\n":
    types.ScratchQueryDocument,
  "\n  fragment ReceivingManifestFragment on ReceivingManifest {\n    advancedShipmentNotification {\n      id\n      partnerAsnId\n    }\n    returnMerchandiseAuthorization {\n      id\n      rmaNumber\n    }\n    createdAt\n    id\n    partner {\n      name\n    }\n    receipts {\n      barcode\n      receivingManifestId\n    }\n    shippingData {\n      trackingNumber\n      expectedArrivalDate\n      carrier {\n        name\n      }\n    }\n  }\n":
    types.ReceivingManifestFragmentFragmentDoc,
  "\n  query ReceivingManifestQuery($receivingManifestIds: [String!]!) {\n    receivingManifests(receivingManifestIds: $receivingManifestIds) {\n      ...ReceivingManifestFragment\n    }\n  }\n":
    types.ReceivingManifestQueryDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query hello {\n    hello\n  }\n"
): (typeof documents)["\n  query hello {\n    hello\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n    query hello {\n      hello\n    }\n  "
): (typeof documents)["\n    query hello {\n      hello\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation AddReceiptFromReceivingManifest(\n    $barcodes: [String!]!\n    $receivingManifestId: UUID!\n    $sublocationReference: SublocationReferenceInput!\n  ) {\n    activateReceipts(\n      barcodes: $barcodes\n      receivingManifestId: $receivingManifestId\n      sublocationReference: $sublocationReference\n    ) {\n      ...ReceivingManifestFragment\n    }\n  }\n"
): (typeof documents)["\n  mutation AddReceiptFromReceivingManifest(\n    $barcodes: [String!]!\n    $receivingManifestId: UUID!\n    $sublocationReference: SublocationReferenceInput!\n  ) {\n    activateReceipts(\n      barcodes: $barcodes\n      receivingManifestId: $receivingManifestId\n      sublocationReference: $sublocationReference\n    ) {\n      ...ReceivingManifestFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation RemoveReceiptFromReceivingManifest(\n    $barcodes: [String!]!\n    $receivingManifestId: UUID!\n  ) {\n    removeReceiptGroupFromReceivingManifest(\n      barcodes: $barcodes\n      receivingManifestId: $receivingManifestId\n    ) {\n      ...ReceivingManifestFragment\n    }\n  }\n"
): (typeof documents)["\n  mutation RemoveReceiptFromReceivingManifest(\n    $barcodes: [String!]!\n    $receivingManifestId: UUID!\n  ) {\n    removeReceiptGroupFromReceivingManifest(\n      barcodes: $barcodes\n      receivingManifestId: $receivingManifestId\n    ) {\n      ...ReceivingManifestFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query ProductDamageMetaData {\n    materials {\n      code\n      displayName\n      description\n      damageTypesAndTheirSeverities {\n        damageType {\n          code\n          displayName\n          description\n        }\n        severities {\n          level\n          description\n        }\n      }\n    }\n    productTypes {\n      code\n      description\n      componentTypes {\n        identifier\n        visibilities {\n          visibility {\n            code\n          }\n        }\n      }\n    }\n  }\n"
): (typeof documents)["\n  query ProductDamageMetaData {\n    materials {\n      code\n      displayName\n      description\n      damageTypesAndTheirSeverities {\n        damageType {\n          code\n          displayName\n          description\n        }\n        severities {\n          level\n          description\n        }\n      }\n    }\n    productTypes {\n      code\n      description\n      componentTypes {\n        identifier\n        visibilities {\n          visibility {\n            code\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query ScratchQuery($receiptIds: [String!]!) {\n    items(barcodes: $receiptIds) {\n      receiptGroupId\n      partner {\n        name\n      }\n      product {\n        sku\n        heroImage\n      }\n      productType {\n        code\n        displayName\n      }\n      receipts {\n        barcode\n      }\n      sublocation {\n        displayName\n      }\n    }\n  }\n"
): (typeof documents)["\n  query ScratchQuery($receiptIds: [String!]!) {\n    items(barcodes: $receiptIds) {\n      receiptGroupId\n      partner {\n        name\n      }\n      product {\n        sku\n        heroImage\n      }\n      productType {\n        code\n        displayName\n      }\n      receipts {\n        barcode\n      }\n      sublocation {\n        displayName\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment ReceivingManifestFragment on ReceivingManifest {\n    advancedShipmentNotification {\n      id\n      partnerAsnId\n    }\n    returnMerchandiseAuthorization {\n      id\n      rmaNumber\n    }\n    createdAt\n    id\n    partner {\n      name\n    }\n    receipts {\n      barcode\n      receivingManifestId\n    }\n    shippingData {\n      trackingNumber\n      expectedArrivalDate\n      carrier {\n        name\n      }\n    }\n  }\n"
): (typeof documents)["\n  fragment ReceivingManifestFragment on ReceivingManifest {\n    advancedShipmentNotification {\n      id\n      partnerAsnId\n    }\n    returnMerchandiseAuthorization {\n      id\n      rmaNumber\n    }\n    createdAt\n    id\n    partner {\n      name\n    }\n    receipts {\n      barcode\n      receivingManifestId\n    }\n    shippingData {\n      trackingNumber\n      expectedArrivalDate\n      carrier {\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query ReceivingManifestQuery($receivingManifestIds: [String!]!) {\n    receivingManifests(receivingManifestIds: $receivingManifestIds) {\n      ...ReceivingManifestFragment\n    }\n  }\n"
): (typeof documents)["\n  query ReceivingManifestQuery($receivingManifestIds: [String!]!) {\n    receivingManifests(receivingManifestIds: $receivingManifestIds) {\n      ...ReceivingManifestFragment\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
