import styled from "styled-components/macro";

export const CheckboxContainer = styled.div`
  display: flex;
  margin: 8px 24px 8px 0;
  gap: 8px;
  cursor: pointer;
`;

export const CheckboxInput = styled.input`
  cursor: pointer;
`;

export const CheckboxLabel = styled.label`
  display: flex;
  gap: 8px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  cursor: pointer;
`;

export const CheckboxGroup = styled.div`
  display: flex;
  flex-dirction: row;
  flex-wrap: wrap;
  margin-top: 4px;
`;
