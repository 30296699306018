import styled from "styled-components/macro";

export const RadioButtonQuestionContent = styled.div`
  margin: 24px 0 48px;
`;

export const RadioButtonQuestionText = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

export const RadioButtonContainer = styled.div`
  display: flex;
  gap: 56px;
  cursor: pointer;

  label,
  input {
    cursor: pointer;
  }
`;

export const RadioButton = styled.div`
  display: flex;
  gap: 12px;

  label {
    display: flex;
    gap: 12px;
  }
`;

export const RadioButtonLink = styled.button`
  font-size: 16px;
  line-height: 22.4px;
  font-weight: 400;
  background-color: transparent;
  color: var(--black);
  cursor: pointer;
  text-decoration: underline;
  height: fit-content;
`;
