import { FC, useCallback } from "react";
import { useImmer } from "use-immer";
import { H1 } from "../../textElements";
import ScanIcon from "../../../assets/icons/ScanIcon.svg";
import EllipsisIcon from "../../../assets/icons/EllipsisIcon.svg";

import {
  BarcodeInputWrapper,
  BarcodeInputIcon,
  BarcodeInputField,
  ErrorContainer,
  BarcodeHeader,
} from "./barcodeInputStyle";
import Tooltip from "../tooltip/Tooltip";
import { EllipsisButton } from "../../pages/conditionAssessment/damageCard/damageCardStyle";

type TooltipContent = {
  linkName: string;
  linkBehavior: () => void;
  isDisabled?: boolean;
};

type BarCodeProps = {
  barcodeTitle: string;
  onSubmitId: (inputValue: string, event: any) => void;
  errorMessage: string;
  placeholder?: string;
  tooltipContent?: TooltipContent[];
};

const BarcodeInput: FC<BarCodeProps> = ({
  barcodeTitle,
  onSubmitId,
  errorMessage,
  placeholder = "Scan or type",
  tooltipContent,
}) => {
  const [inputValue, setInputValue] = useImmer<string>("");

  const handleChange = useCallback(
    (event: any) => {
      const { target } = event;
      setInputValue((draft: any) => (draft = target.value));
    },
    [setInputValue]
  );

  const focusInput = () => {
    const inputField = document.getElementsByClassName(
      "barcode-input"
    )[0] as HTMLElement;
    inputField.focus();
  };

  return (
    <>
      <BarcodeHeader>
        <H1>{barcodeTitle}</H1>
        {tooltipContent && (
          <Tooltip content={tooltipContent}>
            <EllipsisButton>
              <img src={EllipsisIcon} alt="ellipsis" />
            </EllipsisButton>
          </Tooltip>
        )}
      </BarcodeHeader>
      <form
        onSubmit={(event) => {
          onSubmitId(inputValue.trim(), event);
          setInputValue("");
        }}
      >
        <BarcodeInputWrapper onClick={focusInput}>
          <code>
            <BarcodeInputField
              className="barcode-input"
              placeholder={placeholder}
              value={inputValue}
              onChange={handleChange}
            />
          </code>
          <BarcodeInputIcon src={ScanIcon} />
        </BarcodeInputWrapper>
      </form>
      {!!errorMessage && <ErrorContainer>{errorMessage}</ErrorContainer>}
    </>
  );
};

export default BarcodeInput;
