import React, { FC, useRef, useState } from "react";
import { TooltipWrapper, TooltipContent, ButtonLink } from "./tooltipStyle";
import useOutsideClick from "../../../hooks/useOutsideClick";
import { H5 } from "../../textElements";

type TooltipContentData = {
  linkName: string;
  linkBehavior: () => void;
  isDisabled?: boolean;
};

type TooltipTypes = {
  content: TooltipContentData[];
  children: React.ReactNode;
};

const Tooltip: FC<TooltipTypes> = ({ content, children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const refWrapper = useRef<any>();
  useOutsideClick(refWrapper, () => setIsOpen(false));

  return (
    <div ref={refWrapper}>
      <TooltipWrapper onClick={() => setIsOpen(!isOpen)}>
        {children}
        {isOpen && (
          <TooltipContent>
            <>
              {content.map((item, index) => (
                <ButtonLink
                  key={index}
                  disabled={item.isDisabled}
                  onClick={item.linkBehavior}
                >
                  <H5>{item.linkName}</H5>
                </ButtonLink>
              ))}
            </>
          </TooltipContent>
        )}
      </TooltipWrapper>
    </div>
  );
};

export default Tooltip;
