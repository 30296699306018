import React, { FC } from "react";
import { Tabs, TabContent } from "./tabBarStyle";
import TabName from "./TabName";

type TabBarData = {
  tabName: string;
  tabContent: () => JSX.Element;
};

type TabBarProps = {
  tabBarData: TabBarData[];
  activeTab: number;
  controlledFlow?: boolean;
  handleTabClick: React.MouseEventHandler<HTMLElement>;
};

const TabBar: FC<TabBarProps> = ({
  tabBarData,
  activeTab,
  controlledFlow = false,
  handleTabClick,
}) => {
  return (
    <>
      <Tabs>
        {tabBarData.map((tab: any, index) => (
          <TabName
            active={activeTab === index}
            disabled={controlledFlow && activeTab !== index}
            key={index}
            id={index.toString()}
            label={tab.tabName}
            onClick={handleTabClick}
          />
        ))}
      </Tabs>
      <TabContent>{tabBarData[activeTab].tabContent()}</TabContent>
    </>
  );
};

export default TabBar;
