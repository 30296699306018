import { FC } from "react";
import { useModifyDamageCard } from "../../../../hooks/conditionAssessment/useModifyDamageCard";
import { DamageCardContext, DamageInstance } from "./damageCardContext";

import { DamageCardGroup, DamageCardHeader } from "./damageCardStyle";

import PlusIcon from "../../../../assets/icons/PlusIcon.svg";
import DisabledPlusIcon from "../../../../assets/icons/DisabledPlusIcon.svg";
import DamageCard from "./DamageCard";
import Button from "../../../atoms/button/Button";

type DamageCardProviderProps = {
  productType: string;
  initialDamageCards?: DamageInstance[];
};

const FinalDamageCardSet: FC<DamageCardProviderProps> = ({
  productType,
  initialDamageCards,
}) => {
  const {
    damageCards,
    addDamageCard,
    duplicateDamageCard,
    setDamageValue,
    deleteDamageCard,
    resolveDamageCard,
    canAddNewDamageCard,
  } = useModifyDamageCard(productType);

  let initialDamageCardGroup = <></>;

  if (initialDamageCards?.length) {
    initialDamageCardGroup = (
      <>
        <DamageCardHeader>Initial assessment damages</DamageCardHeader>
        <DamageCardGroup isEmpty={false}>
          {initialDamageCards.map((damageCard: DamageInstance) => (
            <DamageCard
              damage={damageCard}
              key={damageCard.id}
              isEditable={false}
            />
          ))}
        </DamageCardGroup>
      </>
    );
  }

  return (
    // shadow any original context that was provided for the initial damage cards
    <DamageCardContext.Provider
      value={{
        setDamageValue,
        duplicateDamageCard,
        deleteDamageCard,
        resolveDamageCard,
        damagesCollection: damageCards,
        addDamageValue: addDamageCard,
      }}
    >
      {initialDamageCardGroup}
      <DamageCardHeader>Final assessment damages</DamageCardHeader>
      <DamageCardGroup isEmpty={damageCards.length === 0}>
        {damageCards.map((damageCard: DamageInstance) => (
          <DamageCard
            damage={damageCard}
            key={damageCard.id}
            isEditable={true}
          />
        ))}
      </DamageCardGroup>
      <Button
        theme="primary"
        isDisabled={!canAddNewDamageCard}
        labelText="Add damage"
        iconSrc={canAddNewDamageCard ? PlusIcon : DisabledPlusIcon}
        onClick={() => addDamageCard()}
      />
    </DamageCardContext.Provider>
  );
};

export default FinalDamageCardSet;
