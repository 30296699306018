import styled from "styled-components/macro";

interface SlideInButtonProps {
  visible: boolean;
  leftOffset: number;
}

export const SlideInButtonContainer = styled.div<SlideInButtonProps>`
  position: fixed;
  left: ${({ leftOffset }) => leftOffset}px;
  width: calc(100% - ${({ leftOffset }) => leftOffset}px);
  padding: 32px 40px;
  background-color: #fff;
  box-shadow: 0px -5px 4px -1px rgba(0, 0, 0, 0.03);
  transition: bottom 0.3s ease-in-out;
  bottom: ${({ visible }) => (visible ? "0" : "-149px")};
`;
