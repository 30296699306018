import React, { FC } from "react";

import {
  ModalHeader,
  ModalSubheader,
  ModalText,
  ModalButtonContainer,
  ModalContent,
  ModalIcon,
} from "./modalStyle";
import ConfirmationIcon from "../../../assets/icons/ConfirmationIcon.svg";
import Button from "../../atoms/button/Button";
import Modal from "./Modal";

type ModalProps = {
  header: string;
  open: boolean;
  subheader?: string;
  iconSrc?: string;
  confirmText?: string;
  onClose: React.MouseEventHandler<HTMLElement>;
  onConfirm: React.MouseEventHandler<HTMLElement>;
};

const ConfirmationModal: FC<ModalProps> = ({
  header,
  open,
  subheader,
  iconSrc = ConfirmationIcon,
  confirmText = "Confirm",
  onClose,
  onConfirm,
}) => {
  const ButtonContainer = (
    <ModalButtonContainer className="button-container">
      <Button
        theme="cancel"
        maxWidth="100%"
        labelText="Cancel"
        onClick={onClose}
      />
      <Button
        theme="confirm"
        maxWidth="100%"
        labelText={confirmText}
        onClick={onConfirm}
      />
    </ModalButtonContainer>
  );

  return (
    <Modal open={open} onClose={onClose}>
      <ModalContent>
        <ModalIcon src={iconSrc} alt="Confirmation" />
        <ModalText>
          <ModalHeader className="modal-header">{header}</ModalHeader>
          {subheader && (
            <ModalSubheader className="modal-subheader">
              {subheader}
            </ModalSubheader>
          )}
        </ModalText>
        {ButtonContainer}
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;
