import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useModifyFunctionalCheck } from "../../../../../hooks/conditionAssessment/useModifyFunctionalCheck";

import { H5 } from "../../../../textElements";

import RadioButtonQuestion from "../../../../elements/radioButtonQuestion/RadioButtonQuestion";
import ConfirmationModal from "../../../../elements/modal/ConfirmationModal";
import FunctionalCheckCheckboxesProvider from "../../functionalCheckCheckboxes/FunctionalCheckCheckboxesProvider";
import SlideInButton from "../../../../elements/slideInButton/SlideInButton";
import { rootDimensions } from "../../../../../globalStyles";

enum RadioOptions {
  YesAnswer = "Yes",
  NoAnswer = "No",
}

type FunctionalCheckProps = {
  moveTab: () => void;
};

const FunctionalCheckContent: FC<FunctionalCheckProps> = ({ moveTab }) => {
  const [openUnsellableModal, setOpenUnsellableModal] =
    useState<boolean>(false);
  const [openBottomButton, setOpenBottomButton] = useState<boolean>(false);
  const {
    hasAllRequiredParts,
    changeRadioSelection,
    hasFunctionalDamage,
    functionalCheckboxes,
  } = useModifyFunctionalCheck();
  const navigate = useNavigate();

  const onNextClick = () => {
    if (hasFunctionalDamage || hasAllRequiredParts === RadioOptions.NoAnswer) {
      //TODO: submit functional change values to BE to track why damaged
      setOpenUnsellableModal(true);
    } else {
      moveTab();
    }
  };

  useEffect(() => {
    if (hasAllRequiredParts !== "") {
      setOpenBottomButton(true);
    }
  }, [hasAllRequiredParts, setOpenBottomButton]);

  const radioButtonSection = (
    <>
      <RadioButtonQuestion
        questionText="Does the item have all required parts & pieces?"
        linkText="View parts list"
        onButtonClick={() => {}}
        onSelect={changeRadioSelection}
        radioValue={hasAllRequiredParts}
      />
    </>
  );

  return (
    <>
      <H5 style={{ marginBottom: "0" }}>
        Please select all applicable structural or functional damages.
      </H5>
      <FunctionalCheckCheckboxesProvider
        functionalCheckboxes={functionalCheckboxes}
      />
      {radioButtonSection}
      <SlideInButton
        leftOffset={rootDimensions.closedSidebarWidth}
        onClick={onNextClick}
        label="Next"
        visible={openBottomButton}
      />
      {openUnsellableModal && (
        <ConfirmationModal
          header="This item is unsellable. Please move it to the Unsellable Zone."
          open={openUnsellableModal}
          confirmText="Start stock move"
          onConfirm={() => navigate("/")}
          onClose={() => setOpenUnsellableModal(false)}
        />
      )}
    </>
  );
};

export default FunctionalCheckContent;
