import styled from "styled-components/macro";

export const H0 = styled.div`
  font-size: 40px;
  line-height: 56px;
  margin-bottom: 24px;
  font-weight: 600;
`;

export const H1 = styled.div`
  font-size: 24px;
  line-height: 33.6px;
  margin-bottom: 24px;
  font-weight: 600;
`;

export const H2 = styled.div`
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px;
  font-weight: 700;
`;

export const H3 = styled.div`
  font-size: 18px;
  line-height: 25.2px;
  margin-bottom: 16px;
  font-weight: 600;
`;

export const H4 = styled.div`
  font-size: 18px;
  line-height: 25.2px;
  margin-bottom: 16px;
  font-weight: 500;
`;

export const H5 = styled.div`
  font-size: 16px;
  line-height: 22.4px;
  margin-bottom: 16px;
  font-weight: 400;
`;

export const H6 = styled.div`
  font-size: 16px;
  line-height: 22.4px;
  margin-bottom: 16px;
  font-weight: 500;
`;

export const P1 = styled.p`
  font-size: 14px;
  line-height: 19.6px;
  font-weight: 600;
`;

export const P2 = styled.p`
  font-size: 14px;
  line-height: 19.6px;
  font-weight: 500;
`;

export const P3 = styled.p`
  font-size: 14px;
  line-height: 19.6px;
  font-weight: 400;
`;

export const P4 = styled.p`
  font-size: 14px;
  line-height: 19.6px;
  font-weight: 400;
  text-decoration: underline;
`;

export const P5 = styled.p`
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
`;

export const P6 = styled.p`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
`;

export const N1 = styled.code`
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
`;

export const N2 = styled.code`
  font-size: 18px;
  line-height: 25.2px;
  font-weight: 400;
`;

export const N3 = styled.code`
  font-size: 14px;
  line-height: 19.6px;
  font-weight: 600;
`;
