import { graphql } from "../../gql/gql";

export const PRODUCT_DAMAGE_QUERY = graphql(/* GraphQL */ `
  query ProductDamageMetaData {
    materials {
      code
      displayName
      description
      damageTypesAndTheirSeverities {
        damageType {
          code
          displayName
          description
        }
        severities {
          level
          description
        }
      }
    }
    productTypes {
      code
      description
      componentTypes {
        identifier
        visibilities {
          visibility {
            code
          }
        }
      }
    }
  }
`);
