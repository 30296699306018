import { useEffect, useState } from "react";
import { DummyData } from "../../components/pages/conditionAssessment/DummyData";
import { useImmer } from "use-immer";

export type CheckboxValueType = {
  value: boolean;
  text: string;
};

export type FunctionalCheckBoxType = {
  title: string;
  values: CheckboxValueType[];
  updateValue: (newValue: boolean, index: number) => void;
};

export function useModifyFunctionalCheck() {
  const [hasAllRequiredParts, setHasAllRequiredParts] = useState<string>("");
  const [hasFunctionalDamage, setHasFunctionalDamage] =
    useState<boolean>(false);

  const [checkboxes, setCheckboxes] = useImmer([
    { title: "Safety", values: DummyData.safetyCheckboxes },
    { title: "Appearance", values: DummyData.appearanceCheckboxValues },
    { title: "Nonfunctional", values: DummyData.nonfunctionalCheckboxValues },
  ]);

  const functionalCheckboxes: FunctionalCheckBoxType[] = checkboxes.map(
    ({ title, values }, checkboxSetIndex) => {
      return {
        title,
        values,
        updateValue: (newValue: boolean, index: number) => {
          setCheckboxes((draft) => {
            draft[checkboxSetIndex].values[index].value = newValue;
          });
        },
      };
    }
  );

  useEffect(() => {
    setHasFunctionalDamage(
      checkboxes.flatMap(({ values }) => values).some((value) => value.value)
    );
  }, [checkboxes]);

  const changeRadioSelection = (newValue: string) => {
    setHasAllRequiredParts(newValue);
  };

  return {
    functionalCheckboxes,
    hasAllRequiredParts,
    hasFunctionalDamage,
    setHasFunctionalDamage,
    changeRadioSelection,
  };
}
