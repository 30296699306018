import styled from "styled-components/macro";

type ExpandIconProps = {
  isOpen: boolean;
};

export const ExpandIconButton = styled.button`
  cursor: pointer;
  background: transparent;
`;

export const ExpandIconImage = styled.img<ExpandIconProps>`
  transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "rotate(0)")};
  transition: 0.5s;
`;
