import React, { FC, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useModifyFunctionalCheck } from "../../../../../hooks/conditionAssessment/useModifyFunctionalCheck";
import { DamageCardContext } from "../../damageCard/damageCardContext";

import { FinalAssessmentContainer } from "./finalAssessmentContentStyle";
import { rootDimensions } from "../../../../../globalStyles";

import ConfirmationModal from "../../../../elements/modal/ConfirmationModal";
import FinalDamageCardSet from "../../damageCard/FinalDamageCardSet";
import FunctionalCheckCheckboxesProvider from "../../functionalCheckCheckboxes/FunctionalCheckCheckboxesProvider";
import SlideInButton from "../../../../elements/slideInButton/SlideInButton";
import BlueArrowIcon from "../../../../../assets/icons/BlueArrowIcon.svg";

type FinalAssessmentProps = {
  productType: string;
};

const FinalAssessmentContent: FC<FinalAssessmentProps> = ({ productType }) => {
  const navigate = useNavigate();
  const onNextClick = () => navigate("/");
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [openUnsellableModal, setOpenUnsellableModal] =
    useState<boolean>(false);
  const [unsellableProduct, setUnsellableProduct] = useState<boolean>(false);
  const { functionalCheckboxes, hasFunctionalDamage } =
    useModifyFunctionalCheck();

  const beforeSubmit = () => {
    // TODO: setUnsellableProduct - depending on response back from API
    if (unsellableProduct || hasFunctionalDamage) {
      setOpenUnsellableModal(true);
    } else {
      setOpenConfirmModal(true);
    }
  };

  // TODO: Don't play with local context/state for damages, let this come from the API
  const { damagesCollection } = useContext(DamageCardContext);

  return (
    <>
      <FinalAssessmentContainer>
        <FinalDamageCardSet
          productType={productType}
          initialDamageCards={damagesCollection}
        />
        <FunctionalCheckCheckboxesProvider
          inExpandableContainer={true}
          functionalCheckboxes={functionalCheckboxes}
        />
        <SlideInButton
          leftOffset={rootDimensions.closedSidebarWidth}
          onClick={beforeSubmit}
          label="Submit"
          visible={true}
        />
      </FinalAssessmentContainer>
      {openConfirmModal && (
        <ConfirmationModal
          header="Please confirm you have completed the final assessment and will now move the item to Repackaging."
          open={openConfirmModal}
          iconSrc={BlueArrowIcon}
          confirmText="Yes, start stock move"
          onConfirm={() => onNextClick()}
          onClose={() => setOpenConfirmModal(false)}
        />
      )}
      {openUnsellableModal && (
        <ConfirmationModal
          header="This item is unsellable. Please confirm you have completed the final assessment and will now move the item to the Unsellable Zone."
          open={openUnsellableModal}
          iconSrc={BlueArrowIcon}
          onConfirm={() => onNextClick()}
          onClose={() => setOpenUnsellableModal(false)}
        />
      )}
    </>
  );
};

export default FinalAssessmentContent;
