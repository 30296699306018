import { FC } from "react";

import { P3 } from "../../textElements";

import { PillLabelWrapper } from "./pillLabelStyle";

type PillLabelType = {
  labelText: string;
  theme: string;
};

const PillLabel: FC<PillLabelType> = ({ labelText, theme }) => {
  return (
    <PillLabelWrapper theme={theme}>
      <P3>{labelText}</P3>
    </PillLabelWrapper>
  );
};

export default PillLabel;
