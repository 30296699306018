import styled from "styled-components/macro";
import { H3, H6 } from "../../../components/textElements";
import { rootDimensions } from "../../../globalStyles";

interface SidebarProps {
  extendSidebar?: boolean;
}

export const SidebarContainer = styled.div<SidebarProps>`
  display: flex;
  position: fixed;
  left: 0;
  width: ${({ extendSidebar }) =>
    extendSidebar ? "224px" : `${rootDimensions.closedSidebarWidth}px`};
  height: 100%;
  padding: 24px;
  background: var(--grayscale1);
  transition: width 350ms ease;
  border-right: 1px solid #d4d8dd;
  overflow-x: hidden;
  z-index: 5;
`;

export const SidebarWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const SidebarHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 24px;
`;

export const SidebarToggleIcon = styled.img<SidebarProps>`
  margin-top: ${({ extendSidebar }) => (extendSidebar ? "0" : "6px")};
  cursor: pointer;
`;

export const SidebarTitle = styled(H3)<SidebarProps>`
  display: ${({ extendSidebar }) => (extendSidebar ? "flex" : "none")};
  margin-bottom: 0;
`;

export const SidebarListContainer = styled.ul<SidebarProps>`
  list-style: none;
  display: ${({ extendSidebar }) => (extendSidebar ? "block" : "none")};
`;

export const SidebarListItem = styled.li`
  margin-top: 0.5rem;
  width: 100%;
  padding: 0.5rem 0.25rem;
  border-radius: 0.2rem;
  cursor: pointer;

  &:hover {
    background: var(--grayscale2);
  }
`;

export const SidebarListItemWrapper = styled.div`
  display: flex;
  align-items: center;
  color: var(--black);
`;

export const SidebarListItemName = styled(H6)<SidebarProps>`
  margin-left: 0.5rem;
  margin-bottom: 0;
`;

export const PageComponent = styled.div`
  padding: 60px 0;
  margin-left: ${rootDimensions.closedSidebarWidth}px;
  padding-left: 40px;
  padding-right: 40px;
  min-height: 100vh;

  @media (min-width: 992px) {
    padding-left: 76px;
  }
`;

export const BackgroundSheet = styled.div`
  z-index: 1;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  touch-action: none;
  border: none;
  pointer-events: auto;
  opacity: 1;
`;
