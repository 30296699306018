import styled from "styled-components/macro";

interface TabsProps {
  active?: boolean;
}

export const Tabs = styled.div`
  display: flex;
`;

export const Tab = styled.button<TabsProps>`
  display: flex;
  max-width: 300px;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  padding-right: 16px;
  color: ${(props) => (props.active ? "var(--black)" : "var(--grayscale5)")};
  background-color: var(--grayscale0);
  font-size: 16px;
  line-height: 22.4px;
  font-weight: 500;

  :disabled {
    cursor: not-allowed;
  }
`;

export const TabContent = styled.div<TabsProps>``;

export const TabButtonContainer = styled.div<TabsProps>``;

export const TabBarLine = styled.div<TabsProps>`
  border: 1.5px solid
    ${(props) => (props.active ? "var(--black)" : "var(--white)")};
  width: calc(100% - 16px);
`;

export const TabNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const TabContentContainer = styled.div``;
