import { graphql } from "../../gql/gql";
// eslint-disable-next-line @typescript-eslint/no-unused-vars -- needed for GQL generation
import { ReceivingManifestFragment } from "../../queries/productReceiving/ReceivingManifest";

export const REMOVE_RECEIPT_MUTATION = graphql(/* GraphQL */ `
  mutation RemoveReceiptFromReceivingManifest(
    $barcodes: [String!]!
    $receivingManifestId: UUID!
  ) {
    removeReceiptGroupFromReceivingManifest(
      barcodes: $barcodes
      receivingManifestId: $receivingManifestId
    ) {
      ...ReceivingManifestFragment
    }
  }
`);
