import React, { FC } from "react";
import {
  SelectionCardRow,
  CardTitle,
  CardBody,
  SelectionButton,
} from "./selectionRowStyle";

type SelectionCardType = {
  cardTitle: string;
  selectedValue?: string;
  options: string[];
  setOption: any;
};

const SelectionRow: FC<SelectionCardType> = ({
  cardTitle,
  selectedValue,
  options,
  setOption,
}) => {
  const selectedButton = (
    <SelectionButton
      isSelected={true}
      type="button"
      onClick={() => onButtonClick("")}
    >
      {selectedValue}
    </SelectionButton>
  );

  const optionsCollection = options.map((option: string, index: number) => (
    <SelectionButton
      key={index}
      isSelected={false}
      type="button"
      onClick={() => onButtonClick(option)}
    >
      {option}
    </SelectionButton>
  ));

  const onButtonClick = (option: string) => {
    setOption(option);
  };

  return (
    <SelectionCardRow className="selection-row">
      <CardTitle>{cardTitle}</CardTitle>
      <CardBody>
        {selectedValue ? <>{selectedButton}</> : <>{optionsCollection}</>}
      </CardBody>
    </SelectionCardRow>
  );
};

export default SelectionRow;
