import { FC } from "react";
import { TextAreaElement, TextAreaField, TextAreaLabel } from "./textAreaStyle";

interface TextAreaProps {
  value: string;
  label: string;
  name: string;
  placeholder?: string;
  rows?: number;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextArea: FC<TextAreaProps> = ({
  value,
  label,
  name,
  rows = 1,
  placeholder = "Enter text here",
  onChange,
}) => (
  <TextAreaElement>
    <TextAreaLabel htmlFor={name}>{label}</TextAreaLabel>
    <TextAreaField
      value={value}
      name={name}
      id={name}
      rows={rows}
      placeholder={placeholder}
      onChange={onChange}
    />
  </TextAreaElement>
);

export default TextArea;
