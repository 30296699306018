import { useImmer } from "use-immer";
import {
  DamageInstance,
  DamageOptionKey,
} from "../../components/pages/conditionAssessment/damageCard/damageCardContext";

export function useModifyDamageCard(productType?: string) {
  const [damageCards, setDamageCards] = useImmer<DamageInstance[]>([]);

  const addDamageCard = () => {
    setDamageCards((draft: DamageInstance[]) => {
      draft.push({
        id: crypto.randomUUID(),
        complete: false,
        resolved: false,
        productType: productType || "",
      });
    });
  };

  const duplicateDamageCard = (damageCard: DamageInstance) => {
    setDamageCards((draft: DamageInstance[]) => {
      draft.push({
        ...damageCard,
        id: crypto.randomUUID(),
      });
    });
    //TODO: Mutation update for BE
  };

  const setDamageValue = (id: string, key: DamageOptionKey, value: string) => {
    setDamageCards((draft: DamageInstance[]) => {
      const damage = draft.find((card: DamageInstance) => card.id === id);
      if (damage) {
        damage[key] = value;
        damage.complete = (
          [
            "componentType",
            "materialType",
            "damageType",
            "severityType",
            "visibilityType",
          ] as DamageOptionKey[]
        ).every((key) => {
          return !!damage[key];
        });
        if (damage.complete) {
          // TODO: mutation - sends full card, id
        }
      }
    });
  };

  const deleteDamageCard = (id: string) => {
    if (id === null) return;
    setDamageCards((draft: DamageInstance[]) => {
      const damage = draft.findIndex((card: DamageInstance) => card.id === id);
      if (damage >= 0) {
        draft.splice(damage, 1);
      }
    });
    //TODO: Mutation update for BE
  };

  // TODO: will need initial collection of damagecards vs final collection to check against.
  const resolveDamageCard = (id: string) => {
    console.log("resolve!");
    // setDamageCards((draft: DamageInstance[]) => {
    //   const damage = draft.find((card: DamageInstance) => card.id === id);
    //   if (damage) {
    //     damage['resolved'] = true;
    //   }
    // });
    //TODO: Mutation update for BE
  };
  const canAddNewDamageCard = damageCards.every(
    (card: DamageInstance) => card.complete
  );

  return {
    damageCards,
    addDamageCard,
    duplicateDamageCard,
    setDamageValue,
    deleteDamageCard,
    resolveDamageCard,
    canAddNewDamageCard,
  };
}
