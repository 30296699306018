import React, { FC } from "react";

import {
  ModalTextHeader,
  ModalTextContent,
  ModalImageContent,
  ModalTextContainer,
} from "./modalStyle";
import { H1 } from "../../textElements";
import Modal from "./Modal";

type ModalProps = {
  open: boolean;
  onClose: React.MouseEventHandler<HTMLElement>;
};

const CustomerNotesModal: FC<ModalProps> = ({ open, onClose }) => {
  const customerNotesData = [
    {
      categoryName: "Materials",
      categoryNotes:
        "I have 3 dogs at home but they aren’t allowed on the couch.",
    },
    {
      categoryName: "Colors",
      categoryNotes:
        "I have 3 dogs at home but they aren’t allowed on the couch.",
    },
    {
      categoryName: "Customer/partner notes",
      categoryNotes:
        "I have 3 dogs at home but they aren’t allowed on the couch.",
    },
  ];

  const customerNotesImages = [
    { src: "https://fakeimg.pl/206x206/" },
    { src: "https://fakeimg.pl/206x206/" },
    { src: "https://fakeimg.pl/206x206/" },
    { src: "https://fakeimg.pl/206x206/" },
  ];

  const customerNotesContent = (
    <>
      {customerNotesData.map((category: any, index: number) => (
        <>
          {!!category.categoryNotes && (
            <ModalTextContainer key={index}>
              <ModalTextHeader>{category.categoryName}</ModalTextHeader>
              <ModalTextContent>{category.categoryNotes}</ModalTextContent>
            </ModalTextContainer>
          )}
        </>
      ))}
    </>
  );

  const customerNotesImageContent = (
    <>
      <ModalTextHeader>Damage images:</ModalTextHeader>
      <ModalImageContent>
        {customerNotesImages.map((image: any, index: number) => (
          <img alt="damages" src={image.src} key={index} />
        ))}
      </ModalImageContent>
    </>
  );

  return (
    <Modal open={open} onClose={onClose}>
      <H1>Customer notes</H1>
      {customerNotesContent}
      {customerNotesImageContent}
    </Modal>
  );
};

export default CustomerNotesModal;
