import { FC } from "react";
import { Receipt, ReceivingManifest } from "../../../../gql/graphql";
import { useModifyManifest } from "../../../../hooks/productReceiving/useModifyManifest";
import { useImmer } from "use-immer";

import {
  AppliedReceiptCardsContainer,
  AppliedReceiptCard,
  AppliedReceiptText,
  AppliedReceiptNumberText,
  AppliedReceiptDeleteButton,
} from "./appliedReceiptStyle";

import CircleXIcon from "../../../../assets/icons/CircleXIcon.svg";
import Notification from "../../../elements/notification/Notification";

type AppliedReceiptCardsProps = {
  receivingManfiest: ReceivingManifest;
  showDeleteButton: boolean;
  setShowDeleteButton: (newValue: boolean) => void;
};

const AppliedReceiptCards: FC<AppliedReceiptCardsProps> = ({
  receivingManfiest,
  showDeleteButton,
  setShowDeleteButton,
}) => {
  const { removeReceipt } = useModifyManifest();
  const [openDeleteReceiptNotification, setOpenDeleteReceiptNotification] =
    useImmer<boolean>(false);
  const receivingManifestId = receivingManfiest?.id || "";

  const onDeleteClick = (barcode: string) => {
    removeReceipt([barcode], receivingManifestId);
    setOpenDeleteReceiptNotification(true);
    setShowDeleteButton(false);
  };

  const AppliedReceiptDeleteButtonContainer = ({
    receipt,
  }: {
    receipt: Receipt;
  }) => {
    return (
      <AppliedReceiptDeleteButton
        onClick={() => onDeleteClick(receipt.barcode)}
      >
        <img src={CircleXIcon} alt="close" />
      </AppliedReceiptDeleteButton>
    );
  };

  const AppliedReceiptTextContainer = ({ receipt }: { receipt: Receipt }) => {
    return (
      <AppliedReceiptText>
        <AppliedReceiptNumberText>{receipt.barcode}</AppliedReceiptNumberText>
      </AppliedReceiptText>
    );
  };

  return (
    <AppliedReceiptCardsContainer>
      {receivingManfiest.receipts.map((receipt: Receipt, index: any) => (
        <AppliedReceiptCard key={index}>
          {showDeleteButton && (
            <AppliedReceiptDeleteButtonContainer receipt={receipt} />
          )}
          <AppliedReceiptTextContainer receipt={receipt} />
        </AppliedReceiptCard>
      ))}
      {openDeleteReceiptNotification && (
        <Notification
          text="Receipt ID deleted"
          open={openDeleteReceiptNotification}
          onClose={() => setOpenDeleteReceiptNotification(false)}
        />
      )}
    </AppliedReceiptCardsContainer>
  );
};

export default AppliedReceiptCards;
