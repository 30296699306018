import styled from "styled-components/macro";

interface ExpandableCardProps {
  height?: number;
}

export const ExpandableCardContainer = styled.div<ExpandableCardProps>`
  display: flex;
  flex-direction: column;
  background-color: var(--grayscale0);
  border: 1px solid var(--grayscale4);
  border-radius: 6px;
  max-width: var(--containerSize);
  width: 100%;
  padding: 12px;
  height: fit-content;
  cursor: pointer;

  &:first-of-type {
    margin-top: 16px;
  }
`;

export const ExpandableCardSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

export const CardBody = styled.div<ExpandableCardProps>`
  height: ${(props) => props.height}px;
  transition: height 0.2s ease-in-out;
  overflow: hidden;
`;
