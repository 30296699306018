import React, { FC, useEffect, useRef, useState } from "react";
import {
  ExpandableCardContainer,
  CardHeader,
  CardBody,
} from "./expandableCardStyle";
import { P3 } from "../../textElements";
import ExpandIcon from "../../atoms/expandIcon/ExpandIcon";

type ExpandableCardProps = {
  headerText: string;
  children: React.ReactNode;
};

const ExpandableCard: FC<ExpandableCardProps> = ({ headerText, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [expandedHeight, setExpandedHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (contentRef.current) {
      setExpandedHeight(contentRef.current.offsetHeight);
    }
  }, [contentRef, setExpandedHeight]);

  return (
    <>
      <ExpandableCardContainer>
        <CardHeader onClick={() => setIsOpen(!isOpen)}>
          <P3>{headerText}</P3>
          <ExpandIcon isOpen={isOpen} setIsOpen={setIsOpen} />
        </CardHeader>
        <CardBody height={isOpen ? expandedHeight : 0}>
          <div ref={contentRef}>{children}</div>
        </CardBody>
      </ExpandableCardContainer>
    </>
  );
};

export default ExpandableCard;
