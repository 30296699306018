import styled from "styled-components/macro";
import { H3, H5, P3 } from "../../textElements";

export const ScannedReceiptContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: var(--containerSize);
  margin-top: 24px;
`;

export const ScannedLocationIdText = styled(H5)`
  color: var(--grayscale6);
  text-transform: uppercase;
`;

export const ReceiptIdNumber = styled(P3)`
  color: var(--grayscale6);
`;

export const LoadingElement = styled(H3)`
  margin-top: 24px;
`;
