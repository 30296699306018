import React from "react";
import { rootDimensions } from "../../../globalStyles";
import { SlideInButtonContainer } from "./slideInButtonStyle";
import Button from "../../atoms/button/Button";

type SlideInButtonProps = {
  visible: boolean;
  label: string;
  leftOffset: number;
  onClick: React.MouseEventHandler<HTMLElement>;
};

const SlideInButton = ({
  visible,
  label,
  leftOffset = rootDimensions.closedSidebarWidth,
  onClick,
}: SlideInButtonProps) => {
  return (
    <SlideInButtonContainer visible={visible} leftOffset={leftOffset}>
      <Button
        theme="slideIn"
        maxWidth="100%"
        labelText={label}
        onClick={onClick}
        isDisabled={!visible}
      />
    </SlideInButtonContainer>
  );
};

export default SlideInButton;
