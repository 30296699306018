import React, { FC } from "react";
import { Tab, TabNameContainer, TabBarLine } from "./tabBarStyle";

type TabProps = {
  active: boolean;
  id: string;
  label: string;
  disabled: boolean;
  onClick: React.MouseEventHandler<HTMLElement>;
};

const TabName: FC<TabProps> = ({ active, id, label, disabled, onClick }) => {
  return (
    <TabNameContainer className="tab">
      <Tab
        className="tab"
        active={active}
        id={id}
        disabled={disabled}
        onClick={onClick}
      >
        {label}
      </Tab>
      <TabBarLine active={active} />
    </TabNameContainer>
  );
};

export default TabName;
