import { FC } from "react";
import { FunctionalCheckCheckboxesContext } from "./functionalCheckCheckboxesContext";
import { FunctionalCheckBoxType } from "../../../../hooks/conditionAssessment/useModifyFunctionalCheck";

import FunctionalCheckCheckboxes from "./FunctionalCheckCheckboxes";
import ExpandableText from "../../../elements/expandableText/ExpandableText";

type FunctionalCheckCheckboxesProviderProps = {
  inExpandableContainer?: boolean;
  functionalCheckboxes: FunctionalCheckBoxType[];
};

const FunctionalCheckCheckboxesProvider: FC<
  FunctionalCheckCheckboxesProviderProps
> = ({ inExpandableContainer = false, functionalCheckboxes }) => {
  return (
    <FunctionalCheckCheckboxesContext.Provider
      value={{
        functionalCheckboxes: functionalCheckboxes,
      }}
    >
      {inExpandableContainer ? (
        <ExpandableText headerText="Capture any missed functional damage">
          <FunctionalCheckCheckboxes />
        </ExpandableText>
      ) : (
        <FunctionalCheckCheckboxes />
      )}
    </FunctionalCheckCheckboxesContext.Provider>
  );
};

export default FunctionalCheckCheckboxesProvider;
