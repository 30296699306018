import ConditionAssessment from "../../pages/conditionAssessment/ConditionAssessment";
import Home from "../../pages/home/Home";
import ProductReceivingInit from "../../pages/productReceiving/ProductReceivingInit";
import ItemReconcilation from "../../pages/itemReconcilation/ItemReconciliation";
import StockMove from "../../pages/stockMove/StockMove";

type SideBardDataItem = {
  name: string;
  path: string;
  rootUrl: string;
  component: React.ComponentType<any>;
};

export const SIDEBAR_DATA: SideBardDataItem[] = [
  {
    name: "Home",
    path: "/",
    rootUrl: "/",
    component: Home,
  },
  {
    name: "Product receiving",
    path: "/product-receiving/:trackingId?",
    rootUrl: "/product-receiving",
    component: ProductReceivingInit,
  },
  {
    name: "Item reconciliation",
    path: "/item-reconcilation/:receiptId?",
    rootUrl: "/item-reconcilation",
    component: ItemReconcilation,
  },
  {
    name: "Condition assessment",
    path: "/condition-assessment/:receiptId?",
    rootUrl: "/condition-assessment",
    component: ConditionAssessment,
  },
  {
    name: "Stock move",
    path: "/stock-move",
    rootUrl: "/stock-move",
    component: StockMove,
  },
];
