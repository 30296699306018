import { createGlobalStyle } from "styled-components";

const primaryRLAColors = {
  black: "#111827",
};

const baseRLAColors = {
  white: "#FFFFFF",
  grayscale1: "#F9FAFB",
};

const accentRLAColors = {
  grayscale2: "#F3F4F6",
  grayscale3: "#E5E7EB",
  grayscale4: "#D1D5DB",
  grayscale5: "#9CA3AF",
  grayscale6: "#6B7280",
  grayscale7: "#4B5563",
};

const notifierRLAColors = {
  red1: "#FFE8E7",
  red2: "#E96962",
  red3: "#B3261E",
  orange1: "#FFEEDA",
  orange2: "#FE9641",
  orange3: "#EA580C",
  green1: "#D8E9E5",
  green2: "#66BC91",
  green3: "#227949",
  blue1: "#EFF6FF",
  blue2: "#93C5FD",
  blue3: "#2563EB",
  purple1: "#F2EFFF",
  purple2: "#A286F4",
  purple3: "#5B2AAE",
  pink1: "#FCE7F3",
  pink2: "#F472B6",
  pink3: "#DB2777",
};

export const rootDimensions = {
  closedSidebarWidth: 72,
};

const GlobalStyle = createGlobalStyle`

  :root {
    /* colors */
    --black: ${primaryRLAColors.black};
    
    --white: ${baseRLAColors.white};
    --grayscale1: ${baseRLAColors.grayscale1};
    
    --grayscale2: ${accentRLAColors.grayscale2};
    --grayscale3: ${accentRLAColors.grayscale3};
    --grayscale4: ${accentRLAColors.grayscale4};
    --grayscale5: ${accentRLAColors.grayscale5};    
    --grayscale6: ${accentRLAColors.grayscale6};
    --grayscale7: ${accentRLAColors.grayscale7};

    --red1: ${notifierRLAColors.red1};
    --red2: ${notifierRLAColors.red2};
    --red3: ${notifierRLAColors.red3};
    --orange1: ${notifierRLAColors.orange1};
    --orange2: ${notifierRLAColors.orange2};
    --orange3: ${notifierRLAColors.orange3};
    --green1: ${notifierRLAColors.green1};
    --green2: ${notifierRLAColors.green2};
    --green3: ${notifierRLAColors.green3};
    --blue1: ${notifierRLAColors.blue1};
    --blue2: ${notifierRLAColors.blue2};
    --blue3: ${notifierRLAColors.blue3};
    --purple1: ${notifierRLAColors.purple1};
    --purple2: ${notifierRLAColors.purple2};
    --purple3: ${notifierRLAColors.purple3};
    --pink1: ${notifierRLAColors.pink1};
    --pink2: ${notifierRLAColors.pink2};
    --pink3: ${notifierRLAColors.pink3};

    /* size */
    --containerSize: 682px;
  }

  * {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    border: none;
    text-decoration: none;
    font-family: "Roboto", monospace;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }

  a {
    text-decoration: none;
  }

  code {
    font-family: "Roboto Mono", monospace;  
    
    input {
      font-family: "Roboto Mono", monospace;  
    }
  }
`;

export default GlobalStyle;
