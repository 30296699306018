import { FC } from "react";
import { ReceivingManifest } from "../../../../gql/graphql";
import { useModifyManifest } from "../../../../hooks/productReceiving/useModifyManifest";

import {
  ReceiptIdNumber,
  ScannedReceiptContainer,
  ScannedLocationIdText,
} from "../productReceivingStyle";

import BarcodeInput from "../../../atoms/barcodeInput/BarcodeInput";
import ConfirmationModal from "../../../elements/modal/ConfirmationModal";
import AppliedReceiptCards from "./AppliedReceiptCards";
import Notification from "../../../elements/notification/Notification";
import { useImmer } from "use-immer";

type AppliedReceiptResultProps = {
  scannedLocationId: string;
  setScannedLocationId: (newValue: string) => void;
  receivingManfiest: ReceivingManifest;
};

const AppliedReceiptResult: FC<AppliedReceiptResultProps> = ({
  scannedLocationId,
  setScannedLocationId,
  receivingManfiest,
}) => {
  const { addReceipt, addReceiptError } = useModifyManifest();

  const [openNewLocationModal, setOpenNewLocationModal] =
    useImmer<boolean>(false);
  const [openAddReceiptNotification, setOpenAddReceiptNotification] =
    useImmer<boolean>(false);

  const [showDeleteButton, setShowDeleteButton] = useImmer<boolean>(false);

  const ReceiptTooltipContent = [
    {
      linkBehavior: () => setOpenNewLocationModal(true),
      linkName: "Add new receiving location",
    },
    {
      linkBehavior: () => setShowDeleteButton(true),
      linkName: "Delete receipt ID",
      isDisabled: receivingManfiest.receipts.length === 0,
    },
  ];

  const addNewLocation = () => {
    setOpenNewLocationModal(false);
    setScannedLocationId("");
  };

  const onSubmitId = (newVal: string, e: Event) => {
    e.preventDefault();
    if (newVal === "") return;

    const receivingManifestId = receivingManfiest?.id || "";
    // TODO: receipt ID value includes warehouse name - parsing in addReceipt
    const warehouseLocation = newVal;

    addReceipt(
      [newVal],
      receivingManifestId,
      scannedLocationId,
      warehouseLocation
    );
    setOpenAddReceiptNotification(true);
  };

  // TODO: Error handling for barcode
  return (
    <>
      <BarcodeInput
        barcodeTitle="Apply label and scan receipt ID"
        errorMessage={addReceiptError?.message || ""}
        placeholder="Scan receipt ID"
        tooltipContent={ReceiptTooltipContent}
        onSubmitId={(newVal, e) => {
          onSubmitId(newVal, e);
        }}
      />
      <ScannedReceiptContainer>
        <ScannedLocationIdText>{scannedLocationId}</ScannedLocationIdText>
        <ReceiptIdNumber>
          Receipt IDs added: {receivingManfiest.receipts.length}
        </ReceiptIdNumber>
      </ScannedReceiptContainer>
      <AppliedReceiptCards
        receivingManfiest={receivingManfiest}
        showDeleteButton={showDeleteButton}
        setShowDeleteButton={setShowDeleteButton}
      />
      {openAddReceiptNotification && (
        <Notification
          text="Receipt ID added"
          open={openAddReceiptNotification}
          onClose={() => setOpenAddReceiptNotification(false)}
        />
      )}
      {openNewLocationModal && (
        <ConfirmationModal
          header="Are you sure you want to add a new location?"
          open={openNewLocationModal}
          onConfirm={() => addNewLocation()}
          onClose={() => setOpenNewLocationModal(false)}
        />
      )}
    </>
  );
};

export default AppliedReceiptResult;
