import React, { FC, useEffect } from "react";
import Portal from "../portal/Portal";
import {
  NotificationElement,
  NotificationText,
  NotificationWrapper,
  NotificationIcon,
} from "./notificationStyle";

import GreenCheckmark from "../../../assets/icons/GreenCheckmark.svg";

type ModalProps = {
  open: boolean;
  text: string;
  onClose: () => void;
};

const Notification: FC<ModalProps> = ({ open, text, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => onClose(), 2500);
    return () => clearTimeout(timer);
  }, [onClose]);

  if (!open) {
    return null;
  }

  return (
    <Portal>
      <NotificationWrapper>
        <NotificationElement>
          <NotificationIcon src={GreenCheckmark} alt="green-checkmark" />
          <NotificationText>{text}</NotificationText>
        </NotificationElement>
      </NotificationWrapper>
    </Portal>
  );
};

export default Notification;
