import React, { FC, useContext, useState } from "react";

import { InitialAssessmentContainer } from "./initialAssessmentContentStyle";

import ConfirmationModal from "../../../../elements/modal/ConfirmationModal";
import Button from "../../../../atoms/button/Button";
import PlusIcon from "../../../../../assets/icons/PlusIcon.svg";
import DisabledPlusIcon from "../../../../../assets/icons/DisabledPlusIcon.svg";

import { DamageCardGroup } from "../../damageCard/damageCardStyle";
import {
  DamageCardContext,
  DamageInstance,
} from "../../damageCard/damageCardContext";
import DamageCard from "../../damageCard/DamageCard";
import SlideInButton from "../../../../elements/slideInButton/SlideInButton";
import { rootDimensions } from "../../../../../globalStyles";

type InitialAssessmentProps = {
  moveTab: () => void;
};

const InitialAssessmentContent: FC<InitialAssessmentProps> = ({ moveTab }) => {
  const [openNoDamageModal, setOpenNoDamageModal] = useState<boolean>(false);
  const [openConfirmDamageModal, setOpenConfirmDamageModal] =
    useState<boolean>(false);

  const { damagesCollection, addDamageValue } = useContext(DamageCardContext);

  const onNextClick = () => {
    if (damagesCollection.length === 0) {
      setOpenNoDamageModal(true);
    } else {
      setOpenConfirmDamageModal(true);
      // on confirm, need to submit status to BE
    }
  };

  const canAddNewDamageCard = damagesCollection.every(
    (card: DamageInstance) => card.complete
  );

  return (
    <InitialAssessmentContainer>
      <DamageCardGroup isEmpty={damagesCollection.length === 0}>
        {damagesCollection.map((damageCard: DamageInstance) => (
          <DamageCard
            damage={damageCard}
            key={damageCard.id}
            isEditable={true}
          />
        ))}
      </DamageCardGroup>
      <Button
        theme="primary"
        isDisabled={!canAddNewDamageCard}
        labelText="Add damage"
        iconSrc={canAddNewDamageCard ? PlusIcon : DisabledPlusIcon}
        onClick={() => addDamageValue()}
      />
      <SlideInButton
        leftOffset={rootDimensions.closedSidebarWidth}
        onClick={onNextClick}
        label="Next"
        visible={canAddNewDamageCard}
      />
      {openNoDamageModal && (
        <ConfirmationModal
          header="By completing, you are confirming there are NO damages on the item."
          open={openNoDamageModal}
          onConfirm={() => moveTab()}
          onClose={() => setOpenNoDamageModal(false)}
        />
      )}
      {openConfirmDamageModal && (
        <ConfirmationModal
          header="Please confirm you have completed the initial assessment and will now begin Refurbishment."
          open={openConfirmDamageModal}
          confirmText="Yes, start Refurbishment"
          onConfirm={() => moveTab()}
          onClose={() => setOpenConfirmDamageModal(false)}
        />
      )}
    </InitialAssessmentContainer>
  );
};

export default InitialAssessmentContent;
