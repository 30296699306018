import styled from "styled-components/macro";

export const TextAreaElement = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: var(--containerSize);
  width: 100%;
  margin-bottom: 40px;
`;

export const TextAreaLabel = styled.label`
  display: flex;
  font-weight: 500;
`;

export const TextAreaField = styled.textarea`
  border: 1px solid var(--grayscale4);
  border-radius: 6px;
  padding: 12px;
  resize: none;

  &:focus {
    border: 1px solid var(--grayscale5);
  }
`;
