import styled from "styled-components/macro";

export const TooltipWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  left: auto;
  right: calc(100% - 24px);
  top: 100%;
  padding: 16px 16px 0;
  color: var(--grayscale6);
  box-shadow: rgba(0, 0, 0, 0.15) 0 2px 6px 0;
  border-radius: 4px;
  background: var(--white);
  z-index: 100;
  white-space: nowrap;
  min-width: 300px;
  width: fit-content;
`;

export const ButtonLink = styled.button`
  display: flex;
  width: 100%;
  background: transparent;
  cursor: pointer;
`;
