import { createContext } from "react";

export type DamageOptionKey =
  | "componentType"
  | "materialType"
  | "damageType"
  | "severityType"
  | "visibilityType";

export type DamageInstance = {
  id: string;
  complete: boolean;
  resolved: boolean;
  productType: string;
  componentType?: string;
  materialType?: string;
  damageType?: string;
  severityType?: string;
  visibilityType?: string;
};

interface ContextValue {
  setDamageValue: (id: string, key: DamageOptionKey, value: string) => void;
  duplicateDamageCard: (damageCard: DamageInstance) => void;
  deleteDamageCard?: (id: string) => void;
  resolveDamageCard: (id: string) => void;
  damagesCollection: DamageInstance[];
  addDamageValue: () => void;
}

export const DamageCardContext = createContext<ContextValue>({
  setDamageValue: () => {},
  duplicateDamageCard: () => {},
  deleteDamageCard: () => {},
  resolveDamageCard: () => {},
  damagesCollection: [],
  addDamageValue: () => {},
});
