import styled from "styled-components/macro";
import { H6 } from "../../textElements";

export const NotificationWrapper = styled.div`
  display: flex;
  position: fixed;
  justify-content: center;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: transparent;
  width: 100%;
  z-index: 10;
`;

export const NotificationElement = styled.div`
  position: relative;
  display: flex;
  gap: 12px;
  flex-direction: row;
  align-items: center;
  max-width: 300px;
  width: fit-content;
  height: max-content;
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 0 2px 6px 0;
  padding: 8px;
  margin-top: 0px;
`;

export const NotificationText = styled(H6)`
  margin-bottom: 0;
`;

export const NotificationIcon = styled.img`
  width: 40px;
  height: 40px;
`;
