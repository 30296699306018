import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useImmer } from "use-immer";

import { H0 } from "../../textElements";

import BarcodeInput from "../../atoms/barcodeInput/BarcodeInput";
import ItemReconciliationResult from "./ItemReconcilationResult";

const ItemReconciliation: FC = () => {
  // TODO: Query for Item Reconciliation Receipt ID response
  // replace useImmer state with direct error from response
  const [receiptIdError, setReceiptIdError] = useImmer<string>("");
  const { receiptId } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <H0>Item Reconciliation</H0>
      {!receiptId ? (
        <BarcodeInput
          barcodeTitle="Scan an item to view possible products for reconciliation"
          placeholder="Scan receipt ID"
          errorMessage={receiptIdError}
          onSubmitId={(newVal, e) => {
            e.preventDefault();
            navigate(`/item-reconcilation/${newVal}`);
          }}
        />
      ) : (
        <ItemReconciliationResult />
      )}
    </>
  );
};

export default ItemReconciliation;
