import { FC } from "react";
import { Receipt, ReceivingManifest } from "../../../../gql/graphql";
import BarcodeInput from "../../../atoms/barcodeInput/BarcodeInput";
import AppliedReceiptResult from "../appliedReceiptResult/AppliedReceiptResult";

type ReceivingLocationResultProps = {
  scannedLocationId: string;
  setScannedLocationId: (newValue: string) => void;
  locationIdError: string;
  receivingManfiest: ReceivingManifest;
};

const ReceivingLocationResult: FC<ReceivingLocationResultProps> = ({
  scannedLocationId,
  setScannedLocationId,
  locationIdError,
  receivingManfiest,
}) => {
  // TODO: Error handling for barcode
  return (
    <>
      {scannedLocationId ? (
        <AppliedReceiptResult
          scannedLocationId={scannedLocationId}
          setScannedLocationId={setScannedLocationId}
          receivingManfiest={receivingManfiest}
        />
      ) : (
        <BarcodeInput
          barcodeTitle="Scan the receiving location"
          errorMessage={locationIdError}
          placeholder="Scan location barcode"
          onSubmitId={(newVal, e) => {
            e.preventDefault();
            setScannedLocationId(newVal);
          }}
        />
      )}
    </>
  );
};

export default ReceivingLocationResult;
