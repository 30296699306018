import { useState, useRef } from "react";
import SidebarList from "./SidebarList";
import HamburgerMenu from "../../../assets/icons/HamburgerMenu.svg";
import useOutsideClick from "../../../hooks/useOutsideClick";

import {
  PageComponent,
  SidebarContainer,
  SidebarWrapper,
  SidebarHeader,
  SidebarToggleIcon,
  SidebarTitle,
  BackgroundSheet,
} from "./sidebarStyle";

const Sidebar = ({ children }: { children: JSX.Element }) => {
  const [extendSidebar, setExtendSidebar] = useState<boolean>(false);
  const refWrapper = useRef<any>();
  useOutsideClick(refWrapper, () => setExtendSidebar(false));

  return (
    <>
      <SidebarContainer ref={refWrapper} extendSidebar={extendSidebar}>
        <SidebarWrapper>
          <SidebarHeader>
            <SidebarToggleIcon
              src={HamburgerMenu}
              alt="nav hamburger menu"
              extendSidebar={extendSidebar}
              onClick={() => setExtendSidebar((currState) => !currState)}
            />
            <SidebarTitle extendSidebar={extendSidebar}>RLA</SidebarTitle>
          </SidebarHeader>
          <SidebarList
            extendSidebar={extendSidebar}
            onClickLink={() => setExtendSidebar(false)}
          />
        </SidebarWrapper>
      </SidebarContainer>
      <PageComponent>{children}</PageComponent>
      {extendSidebar && <BackgroundSheet />}
    </>
  );
};

export default Sidebar;
