import styled from "styled-components/macro";
import { N3 } from "../../../textElements";

export const AppliedReceiptCardsContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
  max-width: var(--containerSize);
  width: 100%;
`;

export const AppliedReceiptCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  width: 100%;
  min-height: 68px;
  background-color: var(--grayscale1);
  border: 1px solid var(--grayscale3);
  border-radius: 6px;
`;

export const AppliedReceiptText = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
`;

export const AppliedReceiptNumberText = styled(N3)`
  display: flex;
  align-self: center;
  text-transform: uppercase;
`;

export const AppliedReceiptDeleteButton = styled.button`
  position: absolute;
  left: 195px;
  top: -10px;
  background-color: transparent;
  cursor: pointer;

  @media (max-width: 768px) {
    left: 175px;
  }
`;
