import { FC } from "react";
import { useImmer } from "use-immer";

import TabBar from "../../elements/tabs/TabBar";

type ItemReconciliationResultProps = {
  // TODO: Send full response ?
};

const ItemReconciliationResult: FC<ItemReconciliationResultProps> = () => {
  const [activeTab, setActiveTab] = useImmer<number>(0);

  const ITEM_RECONCILIATION_TAB_DATA = [
    {
      tabName: "All products",
      tabContent: () => <>All Products Content</>,
    },
    {
      tabName: "Not started",
      tabContent: () => <>Not started</>,
    },
    {
      tabName: "In progress",
      tabContent: () => <>In progress</>,
    },
    {
      tabName: "Complete",
      tabContent: () => <>Complete</>,
    },
  ];

  const handleTabClick = (e: any) => {
    const index = parseInt(e.target.id, 0);
    if (index !== activeTab) {
      setActiveTab(index);
    }
  };

  return (
    <>
      <TabBar
        tabBarData={ITEM_RECONCILIATION_TAB_DATA!}
        activeTab={activeTab}
        handleTabClick={handleTabClick}
      />
    </>
  );
};

export default ItemReconciliationResult;
