import React, { FC, useState } from "react";
import {
  ExpandableTextContainer,
  TextHeader,
  TextContent,
  TextBodyContainer,
} from "./expandableTextStyle";
import ExpandIcon from "../../atoms/expandIcon/ExpandIcon";

type ExpandableTextProps = {
  headerText: string;
  children: React.ReactNode;
  isBold?: boolean;
};

const ExpandableText: FC<ExpandableTextProps> = ({
  headerText,
  children,
  isBold = false,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <ExpandableTextContainer>
      <TextHeader onClick={() => setIsOpen(!isOpen)}>
        <TextContent isBold={isBold}>{headerText}</TextContent>
        <ExpandIcon isOpen={isOpen} setIsOpen={setIsOpen} />
      </TextHeader>
      {isOpen && (
        <TextBodyContainer>
          <div>{children}</div>
        </TextBodyContainer>
      )}
    </ExpandableTextContainer>
  );
};

export default ExpandableText;
