import { FC, useId } from "react";
import { RadioButton, RadioButtonContainer } from "./radioButtonsStyle";

type RadioButtonTypes = {
  onSelect: (newValue: string) => void;
  radioValue: string;
};

const RadioButtons: FC<RadioButtonTypes> = ({ onSelect, radioValue }) => {
  const onOptionChange = (e: any) => {
    onSelect(e.target.value);
  };

  const radioName = useId();
  const yesId = useId();
  const noId = useId();

  return (
    <RadioButtonContainer className="radio-button-group">
      <RadioButton>
        <label>
          <input
            type="radio"
            name={radioName}
            value="Yes"
            id={yesId}
            checked={radioValue === "Yes"}
            onChange={onOptionChange}
          />
          Yes
        </label>
      </RadioButton>
      <RadioButton>
        <label>
          <input
            type="radio"
            name={radioName}
            value="No"
            id={noId}
            checked={radioValue === "No"}
            onChange={onOptionChange}
          />
          No
        </label>
      </RadioButton>
    </RadioButtonContainer>
  );
};

export default RadioButtons;
