import { createContext } from "react";
import { FunctionalCheckBoxType } from "../../../../hooks/conditionAssessment/useModifyFunctionalCheck";

export type FunctionalCheckCheckboxesValue = {
  functionalCheckboxes: FunctionalCheckBoxType[];
};

export const FunctionalCheckCheckboxesContext =
  createContext<FunctionalCheckCheckboxesValue>({
    functionalCheckboxes: [],
  });
