import { FC, useState } from "react";
import styled from "styled-components";
import ProductImageIcon from "../../../assets/icons/ProductImageIcon.svg";
import CustomerNotesModal from "../modal/CustomerNotesModal";

const ProductCardIconContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

const ProductCardImage = styled.img`
  border-radius: 16px;
`;

const ProductCardImageIcon = styled.img`
  position: absolute;
  top: 12px;
  right: 12px;
`;

type Props = {
  heroImage?: string | null;
};

const ItemDetailButton: FC<Props> = ({ heroImage }: Props) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  return (
    <>
      <ProductCardIconContainer onClick={() => setOpenModal(true)}>
        <ProductCardImage alt="product-image" src={heroImage || ""} />
        <ProductCardImageIcon alt="product-image" src={ProductImageIcon} />
      </ProductCardIconContainer>
      {openModal && (
        <CustomerNotesModal
          open={openModal}
          onClose={() => setOpenModal(false)}
        />
      )}
    </>
  );
};

export default ItemDetailButton;
