import { FC, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { PRODUCT_DETAILS_QUERY } from "../../../queries/conditionAssessment/ProductDetails";
import { useModifyDamageCard } from "../../../hooks/conditionAssessment/useModifyDamageCard";
import { DamageCardContext } from "./damageCard/damageCardContext";

import ReceiptGroupCard from "../../elements/ItemDetailCard/ReceiptGroupCard/ReceiptGroupCard";
import TabBar from "../../elements/tabs/TabBar";
import FunctionalCheckContent from "./tabs/functionalCheckContent/FunctionalCheckContent";
import InitialAssessmentContent from "./tabs/initialAssessmentContent/InitialAssessmentContent";
import FinalAssessmentContent from "./tabs/finalAssessmentContent/FinalAssessmentContent";
import { useNavigate } from "react-router-dom";

type ConditionAssessmentResultProps = {
  scannedReceiptId: string;
  setReceiptError: (newValue: string) => void;
};

const ConditionAssessmentResult: FC<ConditionAssessmentResultProps> = ({
  scannedReceiptId,
  setReceiptError,
}) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const navigate = useNavigate();

  // TODO: Need to add error here in this query to replace local error state
  const { data, loading } = useQuery(PRODUCT_DETAILS_QUERY, {
    variables: { receiptIds: [scannedReceiptId] },
    onCompleted(data) {
      if (!data?.items[0]) {
        setReceiptError("Please scan or type a valid receipt ID");
        navigate("/condition-assessment/");
      }
    },
  });

  const productDetails = data?.items[0];
  const productType = productDetails?.productType?.code || "";

  // this is a hacky way to store damage state until the graphql mutations & responses are created
  const {
    damageCards,
    addDamageCard,
    duplicateDamageCard,
    setDamageValue,
    deleteDamageCard,
    resolveDamageCard,
  } = useModifyDamageCard(productType);

  // TODO: This is temp variable into we get actual statuses from BE
  const itemStatus = "";

  useEffect(() => {
    switch (itemStatus as string) {
      case "functional-check":
        setActiveTab(0);
        break;
      case "initial-assessment":
        setActiveTab(1);
        break;
      case "final-assessment":
        setActiveTab(2);
        break;
    }
  }, [itemStatus, setActiveTab]);

  const handleTabClick = (e: any) => {
    const index = parseInt(e.target.id, 0);
    if (index !== activeTab) {
      setActiveTab(index);
    }
  };

  const handleNextClick = (index: number) => {
    if (!CONDITION_ASSESSMENT_TAB_DATA) {
      return;
    }
    const tabLength = CONDITION_ASSESSMENT_TAB_DATA.length - 1;
    if (index === activeTab && activeTab < tabLength) {
      setActiveTab(index + 1);
    }
  };

  const CONDITION_ASSESSMENT_TAB_DATA = productDetails && [
    {
      tabName: "1. Functionality check",
      tabContent: () => (
        <FunctionalCheckContent moveTab={() => handleNextClick(0)} />
      ),
    },
    {
      tabName: "2. Initial assessment",
      tabContent: () => (
        <InitialAssessmentContent moveTab={() => handleNextClick(1)} />
      ),
    },
    {
      tabName: "3. Final assessment",
      tabContent: () => <FinalAssessmentContent productType={productType} />,
    },
  ];

  return (
    <>
      {productDetails ? (
        <>
          <DamageCardContext.Provider
            value={{
              setDamageValue,
              duplicateDamageCard,
              deleteDamageCard,
              resolveDamageCard,
              damagesCollection: damageCards,
              addDamageValue: addDamageCard,
            }}
          >
            <ReceiptGroupCard
              itemDetails={productDetails}
              scannedReceiptId={scannedReceiptId}
            />
            <TabBar
              tabBarData={CONDITION_ASSESSMENT_TAB_DATA!}
              activeTab={activeTab}
              controlledFlow={true}
              handleTabClick={handleTabClick}
            />
          </DamageCardContext.Provider>
        </>
      ) : (
        <>{loading && <div>Loading...</div>}</>
      )}
    </>
  );
};

export default ConditionAssessmentResult;
