import { useMutation } from "@apollo/client";
import { ADDING_RECEIPT_MUTATION } from "../../mutations/productReceiving/AddReceipt";
import { REMOVE_RECEIPT_MUTATION } from "../../mutations/productReceiving/RemoveReceipt";

export function useModifyManifest() {
  const [AddReceipt, { error: addReceiptError }] = useMutation(
    ADDING_RECEIPT_MUTATION
  );

  const handleAddReceipt = (
    barcodes: string[],
    receivingManifestId: string,
    sublocationDisplayName: string,
    warehouseIdentifier: string
  ) => {
    const parsedWarehouseIdentifer = warehouseIdentifier.substring(4, 7);

    const sublocationReference = {
      warehouseIdentifier: parsedWarehouseIdentifer,
      sublocationDisplayName,
    };

    return AddReceipt({
      variables: {
        barcodes,
        receivingManifestId,
        sublocationReference,
      },
    });
  };

  const [RemoveReceipt] = useMutation(REMOVE_RECEIPT_MUTATION, {
    onError: (error) => {
      console.log(error.message);
    },
  });

  const handleRemoveReceipt = (
    barcodes: string[],
    receivingManifestId: string
  ) => {
    return RemoveReceipt({
      variables: {
        barcodes,
        receivingManifestId,
      },
    });
  };

  return {
    addReceipt: handleAddReceipt,
    removeReceipt: handleRemoveReceipt,
    addReceiptError,
  };
}
