import { FC } from "react";
import { ReceivingManifest, Receipt } from "../../../../gql/graphql";
import Button from "../../../atoms/button/Button";

import { ProductCardContainer } from "../../home/searchResultProduct/searchResultProductStyle";
import ReceivingLocationResult from "../receivingLocationResult/ReceivingLocationResult";

import ReceivingManifestDetails from "./ReceivingManifestDetails";

type ProductReceivingResultProps = {
  receivingManifests: any;
  onSubmitTrackingNumber: (newValue: string, event: any) => void;
  scannedLocationId: string;
  setScannedLocationId: (newValue: string) => void;
  locationIdError: string;
  // selectedReceivingManifestReceipts?: Receipt[];
};

const ProductReceivingResult: FC<ProductReceivingResultProps> = ({
  receivingManifests,
  onSubmitTrackingNumber,
  scannedLocationId,
  setScannedLocationId,
  locationIdError,
  // selectedReceivingManifestReceipts,
}) => {
  const onManifestSelection = (manifest: ReceivingManifest, event: Event) => {
    if (manifest.advancedShipmentNotification?.partnerAsnId) {
      onSubmitTrackingNumber(
        manifest.advancedShipmentNotification.partnerAsnId,
        event
      );
    } else if (manifest.returnMerchandiseAuthorization?.rmaNumber) {
      onSubmitTrackingNumber(
        manifest.returnMerchandiseAuthorization.rmaNumber,
        event
      );
    }
  };

  return (
    <>
      {receivingManifests.length > 1 ? (
        <>
          {receivingManifests.map((item: ReceivingManifest) => (
            <ProductCardContainer key={item.id}>
              <ReceivingManifestDetails manifest={item} />
              <Button
                theme="primary"
                labelText="Select this manfiest"
                onClick={(event: any) => onManifestSelection(item, event)}
              />
            </ProductCardContainer>
          ))}
        </>
      ) : (
        <ProductCardContainer>
          <ReceivingManifestDetails manifest={receivingManifests[0]} />
        </ProductCardContainer>
      )}
      {!!receivingManifests[0].receipts && (
        <ReceivingLocationResult
          scannedLocationId={scannedLocationId}
          setScannedLocationId={setScannedLocationId}
          locationIdError={locationIdError}
          receivingManfiest={receivingManifests[0]}
        />
      )}
    </>
  );
};

export default ProductReceivingResult;
