import { graphql } from "../../gql";

export const ReceivingManifestFragment = graphql(/* GraphQL */ `
  fragment ReceivingManifestFragment on ReceivingManifest {
    advancedShipmentNotification {
      id
      partnerAsnId
    }
    returnMerchandiseAuthorization {
      id
      rmaNumber
    }
    createdAt
    id
    partner {
      name
    }
    receipts {
      barcode
      receivingManifestId
    }
    shippingData {
      trackingNumber
      expectedArrivalDate
      carrier {
        name
      }
    }
  }
`);

export const RECEIVING_MANIFEST_QUERY = graphql(/* GraphQL */ `
  query ReceivingManifestQuery($receivingManifestIds: [String!]!) {
    receivingManifests(receivingManifestIds: $receivingManifestIds) {
      ...ReceivingManifestFragment
    }
  }
`);
