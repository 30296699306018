import React, { FC } from "react";
import DownArrow from "../../../assets/icons/DownArrow.svg";
import { ExpandIconButton, ExpandIconImage } from "./expandIconStyle";

type ExpandableCardProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ExpandIcon: FC<ExpandableCardProps> = ({ isOpen, setIsOpen }) => {
  return (
    <>
      <ExpandIconButton onClick={() => setIsOpen((currentVal) => !currentVal)}>
        <ExpandIconImage isOpen={isOpen} src={DownArrow} alt="toggle-arrow" />
      </ExpandIconButton>
    </>
  );
};

export default ExpandIcon;
