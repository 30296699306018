import styled from "styled-components/macro";

interface PillLabelProps {
  theme?: string;
}

type ColorType = {
  location: string;
  furnitureType: string;
  notStartedStatus: string;
  inProgressStatus: string;
  completeStatus: string;
};

const backgroundColors: ColorType = {
  location: "var(--purple1)",
  furnitureType: "var(--blue1)",
  notStartedStatus: "var(--grayscale2)",
  inProgressStatus: "var(--orange1)",
  completeStatus: "var(--green1)",
};

const textColors: ColorType = {
  location: "var(--purple3)",
  furnitureType: "var(--blue3)",
  notStartedStatus: "var(--black)",
  inProgressStatus: "var(--orange3)",
  completeStatus: "var(--green3)",
};

export const PillLabelWrapper = styled.div<PillLabelProps>`
  display: flex;
  width: fit-content;
  padding: 2px 12px;
  gap: 8px;
  background-color: ${({ theme }) =>
    backgroundColors[theme as keyof typeof backgroundColors]};
  color: ${({ theme }) => textColors[theme as keyof typeof textColors]};
  border-radius: 10px;
`;

export const PillLabelContainer = styled.div<PillLabelProps>`
  display: flex;
  flex-flow: wrap;
  width: auto;
  max-width: 550px;
  margin: 8px 0;
  gap: 8px;
`;
