import styled from "styled-components/macro";
import { P3 } from "../../textElements";

interface SelectionRowProps {
  isSelected?: boolean;
}

export const SelectionCardRow = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: var(--white);
  border-bottom: 1px solid var(--grayscale3);
  padding: 8px 0 8px 16px;
  row-gap: 4px;
`;

export const CardTitle = styled(P3)<SelectionRowProps>`
  color: ${(props) =>
    props.isSelected ? "var(--grayscale5)" : "var(--black)"};
`;

export const CardBody = styled.div``;

export const SelectionButton = styled.button<SelectionRowProps>`
  cursor: pointer;
  background-color: ${(props) =>
    props.isSelected ? "var(--grayscale2)" : "var(--white)"};
  border: 1px solid
    ${(props) => (props.isSelected ? "var(--grayscale2)" : "var(--grayscale3)")};
  border-radius: 15px;
  padding: 6px 16px;
  margin: 8px 8px 8px 0;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;

  &:last-of-type {
    margin-right: 0;
  }
`;
