/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A type representing a java.time.Instant formatted in ISO-8601 representation */
  Instant: any;
  /** A type representing a java.time.LocalDate formatted in ISO-8601 representation */
  LocalDate: any;
  /** A type representing a formatted java.util.UUID */
  UUID: any;
};

export type AdvancedShipmentNotification = {
  __typename?: "AdvancedShipmentNotification";
  id: Scalars["UUID"];
  partnerAsnId: Scalars["String"];
};

export type Carrier = {
  __typename?: "Carrier";
  name: Scalars["String"];
};

export type ComponentType = {
  __typename?: "ComponentType";
  code: Scalars["String"];
  createdAt: Scalars["Instant"];
  description: Scalars["String"];
  displayName: Scalars["String"];
};

export type DamageAssessmentPhase = {
  __typename?: "DamageAssessmentPhase";
  code: Scalars["String"];
  createdAt: Scalars["Instant"];
  description: Scalars["String"];
  displayName: Scalars["String"];
};

export type DamageSeverities = {
  __typename?: "DamageSeverities";
  damageType: DamageType;
  severities: Array<Severity>;
};

export type DamageType = {
  __typename?: "DamageType";
  code: Scalars["String"];
  createdAt: Scalars["Instant"];
  description: Scalars["String"];
  displayName: Scalars["String"];
};

export type GeneratedTestData = {
  __typename?: "GeneratedTestData";
  licensePlateNumbers: Array<LicensePlateNumber>;
  partner: Partner;
  receivingManifests: Array<ReceivingManifest>;
  sublocation: Array<Sublocation>;
  warehouse: Warehouse;
};

export type Item = {
  __typename?: "Item";
  partner?: Maybe<Partner>;
  product?: Maybe<Product>;
  productType?: Maybe<ProductType>;
  receiptGroupId?: Maybe<Scalars["UUID"]>;
  receipts: Array<Receipt>;
  status: ItemStatus;
  sublocation?: Maybe<Sublocation>;
};

export type ItemDamageAssessment = {
  __typename?: "ItemDamageAssessment";
  createdAt: Scalars["Instant"];
  damageAssessmentPhase: DamageAssessmentPhase;
  id: Scalars["UUID"];
  item: Item;
};

/** Item status */
export enum ItemStatus {
  Activated = "ACTIVATED",
  Triaged = "TRIAGED",
  Unactivated = "UNACTIVATED",
}

export type LicensePlateNumber = {
  __typename?: "LicensePlateNumber";
  barcode: Scalars["String"];
  partner: Partner;
  product: Product;
  productType?: Maybe<ProductType>;
  sublocation: Sublocation;
};

export type Material = {
  __typename?: "Material";
  code: Scalars["String"];
  createdAt: Scalars["Instant"];
  damageTypesAndTheirSeverities: Array<DamageSeverities>;
  description: Scalars["String"];
  displayName: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  /** Activates the Receipts associated with barcodes and associates them with the ReceivingManifest */
  activateReceipts: ReceivingManifest;
  /** Generates the `amount` number of Receipts and returns the list of corresponding barcodes */
  generateReceipts: Array<Scalars["String"]>;
  /** Generates test Warehouse, ReceivingManifest and Receipt data. */
  generateTestData: GeneratedTestData;
  /** Links the Receipts associated with barcodes to the ReceiptGroup associated with barcodeToLink or a new ReceiptGroup if no barcodeToLink is provided */
  linkReceiptToReceiptGroup: Item;
  /** Remove receipt group from receiving manifest */
  removeReceiptGroupFromReceivingManifest: ReceivingManifest;
  /** Repackages an item */
  repackageItem: Array<LicensePlateNumber>;
  /** Starts an ItemDamageAssessment for the Item associated with the receipt group id and returns the ItemDamageAssessment */
  startItemDamageAssessment: ItemDamageAssessment;
  /** Executes a StockMove for the LicensePlateNumbers associated with the barcodes */
  stockMoveLicensePlateNumbers: Array<LicensePlateNumber>;
  /** Executes a StockMove for the Receipts associated with the barcodes */
  stockMoveReceipts: Array<Receipt>;
};

export type MutationActivateReceiptsArgs = {
  barcodes: Array<Scalars["String"]>;
  receivingManifestId: Scalars["UUID"];
  sublocationReference: SublocationReferenceInput;
};

export type MutationGenerateReceiptsArgs = {
  amount: Scalars["Int"];
  warehouseIdentifier: Scalars["String"];
};

export type MutationGenerateTestDataArgs = {
  amount: Scalars["Int"];
};

export type MutationLinkReceiptToReceiptGroupArgs = {
  barcodeToLink?: InputMaybe<Scalars["String"]>;
  barcodes: Array<Scalars["String"]>;
};

export type MutationRemoveReceiptGroupFromReceivingManifestArgs = {
  barcodes: Array<Scalars["String"]>;
  receivingManifestId: Scalars["UUID"];
};

export type MutationRepackageItemArgs = {
  barcode: Scalars["String"];
};

export type MutationStartItemDamageAssessmentArgs = {
  input: StartItemDamageAssessmentInput;
};

export type MutationStockMoveLicensePlateNumbersArgs = {
  barcodes: Array<Scalars["String"]>;
  sublocationDisplayName: Scalars["String"];
  warehouseIdentifier: Scalars["String"];
};

export type MutationStockMoveReceiptsArgs = {
  barcodes: Array<Scalars["String"]>;
  sublocationDisplayName: Scalars["String"];
  warehouseIdentifier: Scalars["String"];
};

export type Partner = {
  __typename?: "Partner";
  name: Scalars["String"];
};

export type Product = {
  __typename?: "Product";
  heroImage?: Maybe<Scalars["String"]>;
  sku: Scalars["String"];
  title: Scalars["String"];
};

export type ProductComponentType = {
  __typename?: "ProductComponentType";
  componentType: ComponentType;
  identifier: Scalars["String"];
  visibilities: Array<ProductComponentTypeVisibility>;
};

export type ProductComponentTypeVisibility = {
  __typename?: "ProductComponentTypeVisibility";
  createdAt: Scalars["Instant"];
  visibility: Visibility;
};

export type ProductType = {
  __typename?: "ProductType";
  code: Scalars["String"];
  componentTypes: Array<ProductComponentType>;
  description: Scalars["String"];
  displayName: Scalars["String"];
};

export type Query = {
  __typename?: "Query";
  hello: Scalars["String"];
  items: Array<Item>;
  licensePlateNumbers: Array<LicensePlateNumber>;
  licensePlateNumbersInSublocations: Array<LicensePlateNumber>;
  materials: Array<Material>;
  productTypes: Array<ProductType>;
  receivingManifests: Array<ReceivingManifest>;
  sublocation: Sublocation;
  warehouses: Array<Warehouse>;
};

export type QueryItemsArgs = {
  barcodes: Array<Scalars["String"]>;
};

export type QueryLicensePlateNumbersArgs = {
  barcodes: Array<Scalars["String"]>;
};

export type QueryLicensePlateNumbersInSublocationsArgs = {
  sublocationReferences: Array<SublocationReferenceInput>;
};

export type QueryReceivingManifestsArgs = {
  receivingManifestIds: Array<Scalars["String"]>;
};

export type QuerySublocationArgs = {
  sublocationDisplayName: Scalars["String"];
  warehouseIdentifier: Scalars["String"];
};

export type Receipt = {
  __typename?: "Receipt";
  activatedAt?: Maybe<Scalars["Instant"]>;
  barcode: Scalars["String"];
  receivingManifestId?: Maybe<Scalars["UUID"]>;
};

export type ReceivingManifest = {
  __typename?: "ReceivingManifest";
  advancedShipmentNotification?: Maybe<AdvancedShipmentNotification>;
  createdAt: Scalars["Instant"];
  id: Scalars["UUID"];
  lineItemCount: Scalars["Int"];
  partner: Partner;
  receipts: Array<Receipt>;
  returnMerchandiseAuthorization?: Maybe<ReturnMerchandiseAuthorization>;
  shippingData?: Maybe<ShippingData>;
};

export type ReturnMerchandiseAuthorization = {
  __typename?: "ReturnMerchandiseAuthorization";
  id: Scalars["UUID"];
  rmaNumber: Scalars["String"];
};

export type Severity = {
  __typename?: "Severity";
  description: Scalars["String"];
  level: Scalars["Int"];
};

export type ShippingData = {
  __typename?: "ShippingData";
  carrier: Carrier;
  expectedArrivalDate?: Maybe<Scalars["LocalDate"]>;
  expectedShipDate?: Maybe<Scalars["LocalDate"]>;
  id: Scalars["UUID"];
  trackingNumber: Scalars["String"];
};

export type StartItemDamageAssessmentInput = {
  barcode: Scalars["String"];
  damageAssessmentPhaseCode: Scalars["String"];
  productTypeCode: Scalars["String"];
};

export type Sublocation = {
  __typename?: "Sublocation";
  displayName: Scalars["String"];
  zoneType: ZoneType;
};

export type SublocationReferenceInput = {
  sublocationDisplayName: Scalars["String"];
  warehouseIdentifier: Scalars["String"];
};

export type Visibility = {
  __typename?: "Visibility";
  code: Scalars["String"];
  createdAt: Scalars["Instant"];
  description: Scalars["String"];
  displayName: Scalars["String"];
  rank: Scalars["Int"];
};

export type Warehouse = {
  __typename?: "Warehouse";
  displayName: Scalars["String"];
  identifier: Scalars["String"];
};

export type ZoneType = {
  __typename?: "ZoneType";
  displayName: Scalars["String"];
};

export type HelloQueryVariables = Exact<{ [key: string]: never }>;

export type HelloQuery = { __typename?: "Query"; hello: string };

export type AddReceiptFromReceivingManifestMutationVariables = Exact<{
  barcodes: Array<Scalars["String"]> | Scalars["String"];
  receivingManifestId: Scalars["UUID"];
  sublocationReference: SublocationReferenceInput;
}>;

export type AddReceiptFromReceivingManifestMutation = {
  __typename?: "Mutation";
  activateReceipts: { __typename?: "ReceivingManifest" } & {
    " $fragmentRefs"?: {
      ReceivingManifestFragmentFragment: ReceivingManifestFragmentFragment;
    };
  };
};

export type RemoveReceiptFromReceivingManifestMutationVariables = Exact<{
  barcodes: Array<Scalars["String"]> | Scalars["String"];
  receivingManifestId: Scalars["UUID"];
}>;

export type RemoveReceiptFromReceivingManifestMutation = {
  __typename?: "Mutation";
  removeReceiptGroupFromReceivingManifest: {
    __typename?: "ReceivingManifest";
  } & {
    " $fragmentRefs"?: {
      ReceivingManifestFragmentFragment: ReceivingManifestFragmentFragment;
    };
  };
};

export type ProductDamageMetaDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ProductDamageMetaDataQuery = {
  __typename?: "Query";
  materials: Array<{
    __typename?: "Material";
    code: string;
    displayName: string;
    description: string;
    damageTypesAndTheirSeverities: Array<{
      __typename?: "DamageSeverities";
      damageType: {
        __typename?: "DamageType";
        code: string;
        displayName: string;
        description: string;
      };
      severities: Array<{
        __typename?: "Severity";
        level: number;
        description: string;
      }>;
    }>;
  }>;
  productTypes: Array<{
    __typename?: "ProductType";
    code: string;
    description: string;
    componentTypes: Array<{
      __typename?: "ProductComponentType";
      identifier: string;
      visibilities: Array<{
        __typename?: "ProductComponentTypeVisibility";
        visibility: { __typename?: "Visibility"; code: string };
      }>;
    }>;
  }>;
};

export type ScratchQueryQueryVariables = Exact<{
  receiptIds: Array<Scalars["String"]> | Scalars["String"];
}>;

export type ScratchQueryQuery = {
  __typename?: "Query";
  items: Array<{
    __typename?: "Item";
    receiptGroupId?: any | null;
    partner?: { __typename?: "Partner"; name: string } | null;
    product?: {
      __typename?: "Product";
      sku: string;
      heroImage?: string | null;
    } | null;
    productType?: {
      __typename?: "ProductType";
      code: string;
      displayName: string;
    } | null;
    receipts: Array<{ __typename?: "Receipt"; barcode: string }>;
    sublocation?: { __typename?: "Sublocation"; displayName: string } | null;
  }>;
};

export type ReceivingManifestFragmentFragment = {
  __typename?: "ReceivingManifest";
  createdAt: any;
  id: any;
  advancedShipmentNotification?: {
    __typename?: "AdvancedShipmentNotification";
    id: any;
    partnerAsnId: string;
  } | null;
  returnMerchandiseAuthorization?: {
    __typename?: "ReturnMerchandiseAuthorization";
    id: any;
    rmaNumber: string;
  } | null;
  partner: { __typename?: "Partner"; name: string };
  receipts: Array<{
    __typename?: "Receipt";
    barcode: string;
    receivingManifestId?: any | null;
  }>;
  shippingData?: {
    __typename?: "ShippingData";
    trackingNumber: string;
    expectedArrivalDate?: any | null;
    carrier: { __typename?: "Carrier"; name: string };
  } | null;
} & { " $fragmentName"?: "ReceivingManifestFragmentFragment" };

export type ReceivingManifestQueryQueryVariables = Exact<{
  receivingManifestIds: Array<Scalars["String"]> | Scalars["String"];
}>;

export type ReceivingManifestQueryQuery = {
  __typename?: "Query";
  receivingManifests: Array<
    { __typename?: "ReceivingManifest" } & {
      " $fragmentRefs"?: {
        ReceivingManifestFragmentFragment: ReceivingManifestFragmentFragment;
      };
    }
  >;
};

export const ReceivingManifestFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ReceivingManifestFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ReceivingManifest" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "advancedShipmentNotification" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "partnerAsnId" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "returnMerchandiseAuthorization" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "rmaNumber" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "partner" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "receipts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "barcode" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "receivingManifestId" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingData" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trackingNumber" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expectedArrivalDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "carrier" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReceivingManifestFragmentFragment, unknown>;
export const HelloDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "hello" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [{ kind: "Field", name: { kind: "Name", value: "hello" } }],
      },
    },
  ],
} as unknown as DocumentNode<HelloQuery, HelloQueryVariables>;
export const AddReceiptFromReceivingManifestDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddReceiptFromReceivingManifest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "barcodes" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "String" },
                },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "receivingManifestId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sublocationReference" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "SublocationReferenceInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "activateReceipts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "barcodes" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "barcodes" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "receivingManifestId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "receivingManifestId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sublocationReference" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "sublocationReference" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ReceivingManifestFragment" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ReceivingManifestFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ReceivingManifest" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "advancedShipmentNotification" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "partnerAsnId" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "returnMerchandiseAuthorization" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "rmaNumber" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "partner" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "receipts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "barcode" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "receivingManifestId" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingData" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trackingNumber" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expectedArrivalDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "carrier" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddReceiptFromReceivingManifestMutation,
  AddReceiptFromReceivingManifestMutationVariables
>;
export const RemoveReceiptFromReceivingManifestDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RemoveReceiptFromReceivingManifest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "barcodes" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "String" },
                },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "receivingManifestId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "removeReceiptGroupFromReceivingManifest",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "barcodes" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "barcodes" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "receivingManifestId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "receivingManifestId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ReceivingManifestFragment" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ReceivingManifestFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ReceivingManifest" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "advancedShipmentNotification" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "partnerAsnId" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "returnMerchandiseAuthorization" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "rmaNumber" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "partner" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "receipts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "barcode" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "receivingManifestId" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingData" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trackingNumber" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expectedArrivalDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "carrier" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveReceiptFromReceivingManifestMutation,
  RemoveReceiptFromReceivingManifestMutationVariables
>;
export const ProductDamageMetaDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ProductDamageMetaData" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "materials" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "code" } },
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "damageTypesAndTheirSeverities",
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "damageType" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "displayName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "severities" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "level" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "productTypes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "code" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "componentTypes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "identifier" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "visibilities" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "visibility" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProductDamageMetaDataQuery,
  ProductDamageMetaDataQueryVariables
>;
export const ScratchQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ScratchQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "receiptIds" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "String" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "items" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "barcodes" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "receiptIds" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "receiptGroupId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "partner" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "product" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "sku" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "heroImage" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "productType" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayName" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "receipts" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "barcode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sublocation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayName" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ScratchQueryQuery, ScratchQueryQueryVariables>;
export const ReceivingManifestQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ReceivingManifestQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "receivingManifestIds" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "String" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "receivingManifests" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "receivingManifestIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "receivingManifestIds" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ReceivingManifestFragment" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ReceivingManifestFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ReceivingManifest" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "advancedShipmentNotification" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "partnerAsnId" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "returnMerchandiseAuthorization" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "rmaNumber" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "partner" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "receipts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "barcode" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "receivingManifestId" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingData" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trackingNumber" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expectedArrivalDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "carrier" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReceivingManifestQueryQuery,
  ReceivingManifestQueryQueryVariables
>;
