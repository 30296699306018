import styled from "styled-components/macro";
import { H3 } from "../../textElements";

interface ButtonProps {
  theme: string;
  maxWidth?: string;
}

type ColorType = {
  primary: string;
  confirm: string;
  cancel: string;
  slideIn: string;
};

const backgroundColors: ColorType = {
  primary: "var(--grayscale2)",
  confirm: "var(--grayscale3)",
  cancel: "var(--grayscale3)",
  slideIn: "var(--green2)",
};

const textColors: ColorType = {
  primary: "var(--black)",
  confirm: "var(--blue3)",
  cancel: "var(--red3)",
  slideIn: "var(--white)",
};

const disabledBackgroundColors: ColorType = {
  primary: "var(--grayscale3)",
  confirm: "var(--grayscale3)",
  cancel: "var(--grayscale3)",
  slideIn: "var(--green2)",
};

const disabledTextColors: ColorType = {
  primary: "var(--grayscale5)",
  confirm: "var(--grayscale5)",
  cancel: "var(--grayscale5)",
  slideIn: "var(--rekoGreen)",
};

const fontSize: ColorType = {
  primary: "12px",
  confirm: "18px",
  cancel: "18px",
  slideIn: "18px",
};

const lineHeight: ColorType = {
  primary: "16.8px",
  confirm: "25.2px",
  cancel: "25.2px",
  slideIn: "25.2px",
};

const fontWeight: ColorType = {
  primary: "500",
  confirm: "400",
  cancel: "400",
  slideIn: "600",
};

export const ButtonElement = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  max-width: ${({ maxWidth }) => maxWidth}px;
  background-color: ${({ theme }) =>
    backgroundColors[theme as keyof typeof backgroundColors]};
  border: ${({ theme }) =>
    backgroundColors[theme as keyof typeof backgroundColors]};
  color: ${({ theme }) => textColors[theme as keyof typeof textColors]};
  width: 100%;
  padding: 12px;
  cursor: pointer;

  :disabled {
    background-color: ${({ theme }) =>
      disabledBackgroundColors[theme as keyof typeof disabledBackgroundColors]};
    border: ${({ theme }) =>
      disabledBackgroundColors[theme as keyof typeof disabledBackgroundColors]};
    color: ${({ theme }) =>
      disabledTextColors[theme as keyof typeof disabledTextColors]};
    cursor: not-allowed;
  }
`;

export const ButtonLabel = styled(H3)<ButtonProps>`
  font-size: ${({ theme }) => fontSize[theme as keyof typeof fontSize]};
  line-height: ${({ theme }) => lineHeight[theme as keyof typeof lineHeight]};
  font-weight: ${({ theme }) => fontWeight[theme as keyof typeof fontWeight]};
  margin: 0;
`;

export const ButtonImg = styled.img``;
