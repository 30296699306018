import styled from "styled-components/macro";
import { P3 } from "../../textElements";

interface BarcodeInputProps {}

export const BarcodeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: var(--containerSize);
  width: 100%;
`;

export const BarcodeInputWrapper = styled.div<BarcodeInputProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: var(--containerSize);
  height: 42px;
  padding: 0 16px;
  border: 1px solid var(--grayscale4);
  border-radius: 6px;
  cursor: pointer;

  :has(input:focus) {
    border: 1px solid var(--black);
  }
`;

export const BarcodeInputIcon = styled.img<BarcodeInputProps>`
  width: 24px;
  height: 24px;
`;

export const BarcodeInputField = styled.input<BarcodeInputProps>`
  width: calc(100% - 40px);
  min-width: 458px;
  color: var(--grayscale5);

  :focus {
    color: var(--black);
  }
`;

export const ErrorContainer = styled(P3)`
  margin: 8px 0 0 16px;
  color: var(--red3);
`;
