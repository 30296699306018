import { FC, useCallback } from "react";
import { useImmer } from "use-immer";
import { rootDimensions } from "../../../../globalStyles";
import { H1 } from "../../../textElements";

import Input from "../../../atoms/input/Input";
import TextArea from "../../../atoms/textArea/TextArea";
import SlideInButton from "../../../elements/slideInButton/SlideInButton";

type UnknownNumberFormProps = {
  onSubmit: () => void;
};

const UnknownNumberForm: FC<UnknownNumberFormProps> = ({ onSubmit }) => {
  const [formValues, setFormValues] = useImmer({
    trackingNumber: "",
    brand: "",
    carrier: "",
    manufacturer: "",
    additionalInforation: "",
  });

  const handleChange = useCallback(
    (e: any) => {
      const { name, value } = e.target;
      setFormValues((draft: any) => {
        draft[name] = value;
      });
    },
    [setFormValues]
  );

  const UnknownNumberQuestionData = [
    {
      label:
        "If there is a tracking number associated with the item(s), please add below",
      name: "trackingNumber",
      value: formValues.trackingNumber,
    },
    {
      label: "Which brand is the item(s) from?",
      name: "brand",
      value: formValues.brand,
    },
    {
      label: "Which carrier delivered the item(s)?",
      name: "carrier",
      value: formValues.carrier,
    },
    {
      label: "Who is the manufacturer?",
      name: "manufacturer",
      value: formValues.manufacturer,
    },
  ];

  return (
    <>
      <H1>Unknown RMA, ASN, or tracking number</H1>
      {UnknownNumberQuestionData.map((question, index) => {
        return (
          <Input
            type="text"
            key={index}
            label={question.label}
            name={question.name}
            value={question.value}
            onChange={handleChange}
          />
        );
      })}
      <TextArea
        label="Tell us a little bit about the item(s) (furniture type, color, material, etc.)*"
        name="additionalInforation"
        rows={3}
        value={formValues.additionalInforation}
        onChange={handleChange}
      />
      <SlideInButton
        leftOffset={rootDimensions.closedSidebarWidth}
        onClick={onSubmit}
        label="Complete receiving"
        visible={!!formValues.additionalInforation}
      />
    </>
  );
};

export default UnknownNumberForm;
