import { FC } from "react";
import { ButtonElement, ButtonImg, ButtonLabel } from "./buttonStyle";

type ButtonProps = {
  labelText: string;
  theme: string;
  iconSrc?: string;
  maxWidth?: string;
  isDisabled?: boolean;
  onClick: React.MouseEventHandler<HTMLElement>;
};

const Button: FC<ButtonProps> = ({
  labelText,
  theme,
  iconSrc,
  maxWidth = "140",
  isDisabled = false,
  onClick,
}) => {
  return (
    <>
      <ButtonElement
        type="button"
        theme={theme}
        maxWidth={maxWidth}
        disabled={isDisabled}
        onClick={onClick}
      >
        {iconSrc && <ButtonImg src={iconSrc} alt="button-icon" />}
        <ButtonLabel theme={theme}>{labelText}</ButtonLabel>
      </ButtonElement>
    </>
  );
};

export default Button;
