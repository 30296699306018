import { graphql } from "../../gql/gql";
// eslint-disable-next-line @typescript-eslint/no-unused-vars -- needed for GQL generation
import { ReceivingManifestFragment } from "../../queries/productReceiving/ReceivingManifest";

export const ADDING_RECEIPT_MUTATION = graphql(/* GraphQL */ `
  mutation AddReceiptFromReceivingManifest(
    $barcodes: [String!]!
    $receivingManifestId: UUID!
    $sublocationReference: SublocationReferenceInput!
  ) {
    activateReceipts(
      barcodes: $barcodes
      receivingManifestId: $receivingManifestId
      sublocationReference: $sublocationReference
    ) {
      ...ReceivingManifestFragment
    }
  }
`);
