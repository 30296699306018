import React, { FC } from "react";
import { InputElement, InputField, InputLabel } from "./inputStyle";

interface InputProps {
  value: string;
  label: string;
  name: string;
  type: string;
  placeholder?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input: FC<InputProps> = ({
  value,
  label,
  name,
  placeholder = "Enter text here",
  type,
  onChange,
}) => (
  <InputElement className="form-group">
    <InputLabel htmlFor={name}>{label}</InputLabel>
    <InputField
      type={type}
      value={value}
      name={name}
      id={name}
      placeholder={placeholder}
      onChange={onChange}
    />
  </InputElement>
);

export default Input;
