import { FC, useContext, useMemo } from "react";
import { DamageInstance, DamageOptionKey } from "./damageCardContext";
import { PRODUCT_DAMAGE_QUERY } from "../../../../queries/conditionAssessment/ProductDamageOptions";
import { useQuery } from "@apollo/client";
import { upperCaseFirstLetter } from "../../../../utils/utils";

import { DamageCardContext } from "./damageCardContext";
import { EditDamageCardContainer } from "./damageCardStyle";

import SelectionRow from "../../../elements/selectionRow/SelectionRow";

interface DamageCardProps {
  damage: DamageInstance;
  changeToEdit: (newVal: boolean) => void;
}

const EditDamageCard: FC<DamageCardProps> = ({ damage, changeToEdit }) => {
  const { setDamageValue: rootSetDamageValue } = useContext(DamageCardContext);
  const setDamageValue = (key: DamageOptionKey, value: string) => {
    changeToEdit(false);
    rootSetDamageValue(damage.id, key, value);
  };

  const { data: productDamageData, loading } = useQuery(PRODUCT_DAMAGE_QUERY);

  const productTypeData = productDamageData?.productTypes.find(
    (component) => component.code === damage.productType
  );

  const componentTypeOptions = useMemo(() => {
    return (
      productTypeData?.componentTypes.map((component) => {
        return {
          identifier: upperCaseFirstLetter(
            component.identifier.substring(
              component.identifier.indexOf(":") + 1
            )
          ),
          visibilities: component.visibilities,
        };
      }) || []
    );
  }, [productTypeData]);

  const visibilityOptions = useMemo(() => {
    return (
      componentTypeOptions
        .find((component) => component.identifier === damage.componentType)
        ?.visibilities.map((item) =>
          upperCaseFirstLetter(item.visibility.code)
        ) || []
    );
  }, [componentTypeOptions, damage.componentType]);

  const materialOptions =
    productDamageData?.materials.map((component) => component.displayName) ||
    [];

  const damageOptions = useMemo(() => {
    return (
      productDamageData?.materials.find(
        (item) => item.displayName === damage.materialType
      )?.damageTypesAndTheirSeverities || []
    );
  }, [damage.materialType, productDamageData]);

  const severityOptions = useMemo(() => {
    return (
      productDamageData?.materials
        .find((item) => item.displayName === damage.materialType)
        ?.damageTypesAndTheirSeverities.find(
          (item) => item.damageType.displayName === damage.damageType
        )?.severities || []
    );
  }, [damage.materialType, productDamageData, damage.damageType]);

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <EditDamageCardContainer>
          <SelectionRow
            cardTitle="Component"
            selectedValue={damage.componentType}
            setOption={(option: string) => {
              setDamageValue("componentType", option);
              setDamageValue("visibilityType", "");
              setDamageValue("materialType", "");
              setDamageValue("damageType", "");
              setDamageValue("severityType", "");
            }}
            options={componentTypeOptions.map(
              (component) => component.identifier
            )}
          />
          <SelectionRow
            cardTitle="Visibility"
            selectedValue={damage.visibilityType}
            setOption={(option: string) => {
              setDamageValue("visibilityType", option);
              setDamageValue("materialType", "");
              setDamageValue("damageType", "");
              setDamageValue("severityType", "");
            }}
            options={damage.componentType ? visibilityOptions : []}
          />
          <SelectionRow
            cardTitle="Material"
            selectedValue={damage.materialType}
            setOption={(option: string) => {
              setDamageValue("materialType", option);
              setDamageValue("damageType", "");
              setDamageValue("severityType", "");
            }}
            options={damage.visibilityType ? materialOptions : []}
          />
          <SelectionRow
            cardTitle="Damage type"
            selectedValue={damage.damageType}
            setOption={(option: string) => {
              setDamageValue("damageType", option);
              setDamageValue("severityType", "");
            }}
            options={damageOptions.map((item) => item.damageType.displayName)}
          />
          <SelectionRow
            cardTitle="Severity"
            selectedValue={damage.severityType}
            setOption={(option: string) => {
              setDamageValue("severityType", option);
            }}
            options={
              damage.damageType
                ? severityOptions.map((item) => item?.description)
                : []
            }
          />
        </EditDamageCardContainer>
      )}
    </>
  );
};

export default EditDamageCard;
